import React from 'react'
import { IconsProps } from './dto';

export function TickIcon(props : IconsProps) {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.3332 1L3.99984 8.33333L0.666504 5" stroke= {props.color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
