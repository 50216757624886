
import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import { keycloak } from '../../../lib/keycloak/index.ts';
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hook.ts';
import { colors } from '../../colors.ts';
import { BreadcrumbsIcon } from '../../icons/breadcrumbIcon.tsx';
import { CalendarIcon } from '../../icons/calendarIcon.tsx';
import { ChatIcon } from '../../icons/chatIcon.tsx';
import { CompanyIcon } from '../../icons/companyIcon.tsx';
import { DashboardIcon } from '../../icons/dashboardIcon.tsx';
import { DocumentIcon } from '../../icons/documentIcon.tsx';
import { FinancialAdvisorIcon } from '../../icons/financialAdvisorIcon.tsx';
import { LogoExtended } from '../../icons/logoExtended.tsx';
import { LogoIcon } from '../../icons/logoIcon.tsx';
import { NoticeIcon } from '../../icons/noticeIcon.tsx';
import { OperatorIcon } from '../../icons/operatorIcon.tsx';
import { SettingIcon } from '../../icons/settingIcon.tsx';
import { MenuStatus } from '../../layout/dto.ts';
import { setMenuStatus } from '../../layout/slice.ts';
import Avatar from '../../molecules/avatar/index.tsx';
import { MenuItems } from './dto.ts';
import NavLinks from './nav-links.tsx';

const uriMap = new Map<MenuItems, string>([
  [MenuItems.DASHBOARD, '/'],
  [MenuItems.COMPANY, '/company'],
  [MenuItems.FINANCIAL_ADVISOR, '/financial-advisor'],
  [MenuItems.OPERATOR, '/operator'],
  [MenuItems.COLLABORATOR, '/collaborator'],
  [MenuItems.NOTICE, '/notice'],
  [MenuItems.DOCUMENT, '/document-company'],
  [MenuItems.CHAT, '/message'],
  [MenuItems.CALENDAR, '/calendar'],
  [MenuItems.SETTING, '/setting']
])

const labelMap = new Map<MenuItems, string>([
  [MenuItems.DASHBOARD, 'Dashboard'],
  [MenuItems.COMPANY, 'Aziende'],
  [MenuItems.FINANCIAL_ADVISOR, 'Commercialisti'],
  [MenuItems.OPERATOR, 'Operatori'],
  [MenuItems.COLLABORATOR, 'Collaboratori'],
  [MenuItems.NOTICE, 'Avvisi'],
  [MenuItems.DOCUMENT, 'Documenti'],
  [MenuItems.CHAT, 'Messaggi'],
  [MenuItems.CALENDAR, 'Calendario'],
  [MenuItems.SETTING, 'Impostazioni']
])

const iconMap = new Map<MenuItems, ReactElement>([
  [MenuItems.DASHBOARD, <DashboardIcon size={"20"} color={''} />],
  [MenuItems.COMPANY, <CompanyIcon size={'20'} color={''} />],
  [MenuItems.FINANCIAL_ADVISOR, <FinancialAdvisorIcon size='20' />],
  [MenuItems.OPERATOR, <OperatorIcon size={"20"} color={''} />],
  [MenuItems.COLLABORATOR, <OperatorIcon size={"20"} color={''} />],
  [MenuItems.NOTICE, <NoticeIcon size={'20'} color={''} />],
  [MenuItems.DOCUMENT, <DocumentIcon size={'20'} color={''} />],
  [MenuItems.CHAT, <ChatIcon size={'20'} color={''} />],
  [MenuItems.CALENDAR, <CalendarIcon size={'20'} color={''} />],
  [MenuItems.SETTING, <SettingIcon color={''} size={'20'} />]
])

export default function SidebarComponent(props: { menuItem: MenuItems }) {
  const { menuItem } = props

  const layoutState = useAppSelector(state => state.layout)
  const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
  const objectState = useAppSelector(state => state.object)
  const dispatch = useAppDispatch()

  let logo: ReactNode[] = []

  if (!keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company")) {
    if (layoutState.menuStatus === MenuStatus.CLOSED) {
      logo.push(
        <LogoIcon key={'2001'}/>
      )
    } else {
      logo.push(
        <LogoExtended key={'2001'}/>
      )
    }
  } else {
    if (layoutState.menuStatus === MenuStatus.CLOSED) {
      logo.push(
        <Avatar key={'2001'}
          type="single"
          size="md"
          shape="circle"
          imageUrl={objectState.findAvatarFinancialResponse!}
          altTextInitials={(financialAdvisorState.findByIdFinancialAdvisorResponse?.name[0] || '') + (financialAdvisorState.findByIdFinancialAdvisorResponse?.surname[0] || '')}
        />
      )
    } else {
      logo.push(
        <Avatar key={'2001'}
          type="info"
          size="sm"
          firstRow={financialAdvisorState.findByIdFinancialAdvisorResponse?.commercialName!}
          imageUrl={objectState.findAvatarFinancialResponse!}
          altTextInitials={(financialAdvisorState.findByIdFinancialAdvisorResponse?.name[0] || 'A') + (financialAdvisorState.findByIdFinancialAdvisorResponse?.surname[0] || 'A')}
        />
      )
    }
  }

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: colors.neutral[100], overflow: 'auto' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}
        className={clsx(
          'h-[64px] lg:h-[80px]',
          {
            'lg:py-[16px] lg:px-[20px] py-[16px] px-[24px] justify-between': layoutState.menuStatus !== MenuStatus.CLOSED,
            'lg:py-[16px] lg:px-[0px] justify-center': layoutState.menuStatus === MenuStatus.CLOSED,
          }
        )}>
        {logo}
        <div
          onClick={() => {
            if (layoutState.menuStatus === MenuStatus.OPEN) {
              dispatch(setMenuStatus(MenuStatus.CLOSED))
            } else if (layoutState.menuStatus === MenuStatus.HOVER) {
              dispatch(setMenuStatus(MenuStatus.OPEN))
            }
          }}
          className={
            clsx(
              'transition-all hidden',
              {
                'rotate-0 lg:block': layoutState.menuStatus === MenuStatus.OPEN,
                'rotate-180 lg:block': layoutState.menuStatus === MenuStatus.HOVER,
                'hidden': layoutState.menuStatus === MenuStatus.CLOSED
              }
            )
          }>
          <BreadcrumbsIcon />
        </div>
      </div>
      {
        layoutState.menuItems.map((item) => (
          <div key={labelMap.get(item)} className={"flex flex-col items-start justify-start px-4 py-1"}>
            <NavLinks
              menuItem={menuItem}
              link={
                {
                  href: uriMap.get(item),
                  name: labelMap.get(item),
                  icon: iconMap.get(item),
                  menuItem: item
                }
              }
            />
          </div>
        ))
      }
      <div
        style={{ padding: '24px 16px 24px 16px', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
        className='text-label-sm font-medium text-neutral-500 transition-all'
      >
        <span
          className={
            clsx(
              'transition-all',
              {
                "block": layoutState.menuStatus === MenuStatus.CLOSED,
                "hidden content-['© 2024 Fiscalbright']": layoutState.menuStatus !== MenuStatus.CLOSED
              }
            )
          }>
          ©
        </span>
        <span
          className={
            clsx(
              'transition-all',
              {
                "hidden": layoutState.menuStatus === MenuStatus.CLOSED,
                "block": layoutState.menuStatus !== MenuStatus.CLOSED
              }
            )
          }>
          © 2024 Fiscalbright
        </span>
      </div>
    </div>

  );
}
