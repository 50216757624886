import { format } from 'date-fns'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { findFileById, setFindDocumentStatus } from '../../lib/object/slice.ts'
import { useAppDispatch, useAppSelector } from '../../lib/redux/hook.ts'
import { colors } from '../../ui/colors.ts'
import { CheckCircleIcon } from '../../ui/icons/checkCircleIcon.tsx'
import { DashboardIcon } from '../../ui/icons/dashboardIcon.tsx'
import { DownloadIcon } from '../../ui/icons/downloadIcon.tsx'
import { FinancialAdvisorIcon } from '../../ui/icons/financialAdvisorIcon.tsx'
import { OperatorIcon } from '../../ui/icons/operatorIcon.tsx'
import { OtherIcon } from '../../ui/icons/otherIcon.tsx'
import { PdfIcon } from '../../ui/icons/pdfIcon.tsx'
import { XlsxIcon } from '../../ui/icons/xlsxIcon.tsx'
import { ZipIcon } from '../../ui/icons/zipIcon.tsx'
import { LayoutComponent } from '../../ui/layout/index.tsx'
import { SpinnerComponent } from '../../ui/molecules/spinner/index.tsx'
import { StatsCard } from '../../ui/molecules/statsCard/index.tsx'
import { StatsCardAlt } from '../../ui/molecules/statsCardAlt/index.tsx'
import { EmptyList } from '../../ui/organisms/emptyList/index.tsx'
import { GroupedRow } from '../../ui/organisms/groupedRow/index.tsx'
import { SegmentControl } from '../../ui/organisms/segmentControl/index.tsx'
import { MenuItems } from '../../ui/organisms/sidebar/dto.ts'
import { DocumentDTO, DocumentF24Status, DocumentType } from "../company/documents/dto.ts"
import { findAllDocuments, findByIdDocument, findExtensionsFiles, setDocumentFilterCompanyId, setDocumentFilterFileName, setDocumentFilterItemsPerPage, setDocumentFilterMonth, setDocumentFilterPage, setDocumentFilterReceivedGroup, setDocumentFilterSenderGroup, setDocumentFilterStatus, setDocumentFilterType, setDocumentFilterTypology, setDocumentFilterYear, setFindAllDocumentsStatus, setFindExtensionsFilesStatus } from '../company/documents/slice.ts'
import { findAllCompany, setSelectedDocumentTab, setSelectedOperationTab } from '../company/slice.ts'
import { findAllCollaborator } from '../financialAdvisor/collaborator/slice.ts'
import { financialAdvisorActiveSubscription, setSelectedTab } from '../financialAdvisor/slice.ts'
import { setSelectedDocumentType } from './slice.ts'
import { findChatsByLoggedUser, findLastMessage, setFindChatsByLoggedUserStatus } from '../company/chat/slice.ts'
import clsx from 'clsx'
import { NewObjectService } from '../../lib/object/service.ts'
import { MessageFilterDTO } from '../company/chat/dto.ts'
import { CompanyIcon } from '../../ui/icons/companyIcon.tsx'

export function DashboardFinancialAdvisor() {
    const layoutState = useAppSelector(state => state.layout)
    const collaboratorState = useAppSelector(state => state.collaborator)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const documentState = useAppSelector(state => state.document)
    const dashboardState = useAppSelector(state => state.dashboard)
    const chatState = useAppSelector(state => state.chat)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const me = authState.findMeFinancialAdvisorResponse

    const [noticeF24, setNoticeF24] = useState<DocumentDTO[]>([])
    const [allDocuments, setAllDocuments] = useState<DocumentDTO[]>([])
    const objectService = NewObjectService()
    let documentRow: ReactNode[] = []

    const iconMap = new Map<string, ReactElement>([
        [".png", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".jpg", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".jpeg", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
        [".pdf", <PdfIcon size={"24"} color={colors.red[500]} />],
        [".xlsx", <XlsxIcon size={"24"} color={colors.green[500]} />],
        [".xls", <XlsxIcon size={"24"} color={colors.green[500]} />],
        [".zip", <ZipIcon size={"24"} color={colors.brandPrimary[500]} />],
    ]);

    useEffect(() => {
        if (me) {
            dispatch(financialAdvisorActiveSubscription(me.id))
            dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
            dispatch(findAllCompany(companyState.companyFilters))
            dispatch(findChatsByLoggedUser())
            dispatch(setDocumentFilterItemsPerPage(5))
            setDocumentFilterType(DocumentType.balance)
            dispatch(findAllDocuments({
                companyId: null,
                itemsPerPage: 20,
                order: true,
                page: 0,
                receiverGroup: "",
                referenceYear: 0,
                referenceMonth: 0,
                senderGroup: "operators",
                sort: "",
                type: "",
                typology: "",
                fileName: "",
                status: ""
            }))
        }
    }, [me])

    useEffect(() => {
        if (chatState.findChatsByLoggedUserStatus === "successfully") {
            let request: { chatId: number, filter: MessageFilterDTO }[] = []
            chatState.findChatByLoggedUserResponse.
                filter((element) => element.lastMessageDate !== null).
                sort((a, b) => new Date(b.lastMessageDate).getTime() - new Date(a.lastMessageDate).getTime()).
                forEach((chat) => (
                    request.push({
                        chatId: chat.id, filter: {
                            page: 0,
                            itemsPerPage: (chat && chat.notSeenMessages > 0 ? (chat.notSeenMessages + 25) : 25),
                            order: chatState.messageFilter.order,
                            sort: chatState.messageFilter.sort
                        }
                    })
                ))
            dispatch(findLastMessage(request))
        }
    }, [chatState.findChatsByLoggedUserStatus])

    useEffect(() => {
        if (chatState.findChatsByLoggedUserStatus === 'successfully' || chatState.findChatsByLoggedUserStatus === 'failed')
            setFindChatsByLoggedUserStatus('idle')
    }, [chatState.findChatsByLoggedUserStatus])

    useEffect(() => {
        if (documentState.findAllDocumentsStatus === "successfully" && documentState.findAllDocumentsResponse !== undefined) {
            if (!noticeF24.length) {
                setNoticeF24(documentState.findAllDocumentsResponse.data.
                    filter(item => item.f24Status === DocumentF24Status.Delay))
            }
            if (!allDocuments.length)
                setAllDocuments(documentState.findAllDocumentsResponse.data)
            const updatedRequest = {
                ...documentState.findExtensionsFilesRequest,
                ids: [
                    ...documentState.findExtensionsFilesRequest.ids,
                    ...documentState.findAllDocumentsResponse.data.map(data => String(data.objectId))
                ]
            };
            dispatch(findExtensionsFiles(updatedRequest))
        }
    }, [documentState.findAllDocumentsStatus])

    if (documentState.findAllDocumentsStatus === "successfully" &&
        documentState.findAllDocumentsResponse !== undefined &&
        documentState.findExtensionsFilesStatus === "successfully" &&
        companyState.findAllCompanyResponse !== undefined &&
        companyState.findAllCompanyStatus === 'successfully'
    ) {
        documentState.findAllDocumentsResponse.data.slice(0, 5).filter(item =>
            item.type === documentState.documentFilters.type).forEach((item, index) => {
                documentRow.push(
                    <GroupedRow
                        key={item.id}
                        toRead={item.companiesDownloadDate !== null}
                        icon={iconMap.get(documentState.findExtensionsFilesResponse?.extensions[index]!)!}
                        label={item.fileName}
                        subLabel={format(item.date, "dd-MM-yyyy") + " a "}
                        thirdLabel={companyState.findAllCompanyResponse?.data.
                            find(element => element.id.toString() === item.companyId.toString())?.companyName}
                        secondIcon={
                            <DownloadIcon
                                size={"24"}
                                color={layoutState.theme[500]}
                                onClick={() => {
                                    dispatch(findFileById(item.objectId.toString())).then((e) => {
                                        //@ts-ignore
                                        objectService.downloadBase64WithExtension(item.fileName, e.payload !== null ? e.payload : '')
                                        dispatch(findByIdDocument(item.id.toString())).then(() => {
                                            dispatch(setFindDocumentStatus('idle'))
                                            dispatch(findAllDocuments(documentState.documentFilters))
                                        })
                                    })
                                }}
                            />}
                    />
                )
            })
    }
    return (
        <LayoutComponent
            menuItem={MenuItems.DASHBOARD}
            breadcrumbItems={['Dashboard']}
            headingLabel={"Dashboard"}
            headingButtons={[]}
            breadcrumbIcon={<DashboardIcon color={layoutState.theme[500]} size="24" />}
        >
            <div className={"flex flex-col gap-3 w-full"}>
                <div className={"flex flex-row gap-4 justify-between pb-6"}>
                    <div className=" flex flex-col w-full gap-2">
                        <div className="flex flex-row gap-4 flex-grow ">
                            <div className='  flex flex-row gap-4 basis-[50%]'>
                                <StatsCard title={"Aziende"} value={companyState.findAllCompanyResponse ?
                                    companyState.findAllCompanyResponse.total.toString() : <SpinnerComponent />}
                                    icon={<FinancialAdvisorIcon size={"28"} color={layoutState.theme[500]} />}
                                    onClick={() => navigate('/company')}
                                />
                                <StatsCard title={"Collaboratori"} value={collaboratorState.findAllCollaboratorResponse ?
                                    collaboratorState.findAllCollaboratorResponse.total.toString() : <SpinnerComponent />}
                                    icon={<OperatorIcon size={"28"} color={layoutState.theme[500]} />}
                                    onClick={() => navigate('/collaborator')}
                                />
                            </div>
                            <div className='basis-[50%]'>
                                <StatsCardAlt rightLabel={financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit !== 'free' &&
                                    <div className=' flex flex-row items-center gap-2'>
                                        <span className={clsx("", {
                                            'text-green-500':
                                                financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate!.toLocaleString().localeCompare(new Date().toLocaleDateString()),
                                            'text-red-900':
                                                !financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.toLocaleString().localeCompare(new Date().toLocaleDateString())
                                        })}>
                                            {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate!.
                                                toLocaleString().localeCompare(new Date().toLocaleDateString()) ? 'Attivo' : 'Scaduto'}
                                        </span>
                                        {financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate!.
                                            toLocaleString().localeCompare(new Date().toLocaleDateString()) && <CheckCircleIcon />}
                                    </div>
                                }
                                    title={'Piano ' + (financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies !== 0 ?
                                        financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit.toString().toUpperCase() : 'Da attivare'.toUpperCase())}
                                    content={
                                        <>
                                            <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                <span className=" text-brandPrimary-850 font-semibold">Max documenti - aziende</span>
                                                <span className=" text-brandSecondary-500 text-text-lg">{
                                                    financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionTierLimit === 'unlimited' ? '∞ - ∞' :
                                                        financialAdvisorState.financialAdvisorActiveSubscriptionResponse &&
                                                        financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfDocuments + " - " + financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies}
                                                </span>
                                            </div>
                                            <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                <span className=" text-brandPrimary-850 font-semibold">Data attivazione</span>
                                                <span className=" text-brandSecondary-500">
                                                    {
                                                        financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies !== 0 ?
                                                            new Date(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.renewalDate as Date).toLocaleDateString() :
                                                            'N/A'
                                                    }
                                                </span>
                                            </div>
                                            <div className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4">
                                                <span className=" text-brandPrimary-850 font-semibold">Prossimo rinnovo</span>
                                                <span className=" text-brandSecondary-500">{
                                                    financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.maxNumberOfCompanies !== 0 ?
                                                        new Date(financialAdvisorState.financialAdvisorActiveSubscriptionResponse?.subscriptionExpirationDate as Date).toLocaleDateString() :
                                                        'N/A'
                                                }
                                                </span>
                                            </div>
                                        </>
                                    }
                                    label="Gestisci abbonamento"
                                    onClick={() => {
                                        dispatch(setSelectedTab(2))
                                        navigate('/setting')
                                    }}
                                />
                            </div>
                        </div>
                        <div className='flex flex-row gap-4 flex-grow'>
                            <div className="p-6 rounded-3xl flex flex-col flex-grow basis-[50%] gap-6 border shadow-md w-full" style={{ borderColor: layoutState.theme[200], boxShadow: "0px 4px 24px -8px rgba(26, 37, 255, 0.08)" }}>
                                <div className="flex flex-row gap-2 items-center">
                                    <OtherIcon size={"24"} color={layoutState.theme[400]} />
                                    <span className="text-label-md font-bold text-neutral-800">Ultimi documenti inviati</span>
                                </div>
                                <div className="flex flex-row justify-start gap-3 w-1/2">
                                    <SegmentControl
                                        selected={dashboardState.selectedDocumentType === 0}
                                        label={"Bilanci"}
                                        onClick={() => {
                                            dispatch(setFindExtensionsFilesStatus("idle"))
                                            dispatch(setDocumentFilterType(DocumentType.balance))
                                            dispatch(setDocumentFilterItemsPerPage(5))
                                            dispatch(setFindAllDocumentsStatus("idle"))
                                            dispatch(setSelectedDocumentType(0))
                                            dispatch(findAllDocuments({
                                                companyId: null,
                                                itemsPerPage: 20,
                                                order: false,
                                                page: 0,
                                                receiverGroup: "",
                                                referenceYear: 0,
                                                referenceMonth: 0,
                                                senderGroup: "operators",
                                                sort: "",
                                                type: DocumentType.balance,
                                                typology: "",
                                                fileName: "",
                                                status: ""
                                            }))
                                        }}
                                    />
                                    <SegmentControl
                                        selected={dashboardState.selectedDocumentType === 1}
                                        label={"F24"}
                                        onClick={() => {
                                            dispatch(setFindExtensionsFilesStatus("idle"))
                                            dispatch(setDocumentFilterType(DocumentType.f24))
                                            dispatch(setDocumentFilterItemsPerPage(5))
                                            dispatch(setFindAllDocumentsStatus("idle"))
                                            dispatch(setSelectedDocumentType(1))
                                            dispatch(findAllDocuments({
                                                companyId: null,
                                                itemsPerPage: 20,
                                                order: false,
                                                page: 0,
                                                receiverGroup: "",
                                                referenceYear: 0,
                                                referenceMonth: 0,
                                                senderGroup: "operators",
                                                sort: "",
                                                type: DocumentType.f24,
                                                typology: "",
                                                fileName: "",
                                                status: ""
                                            }))
                                        }}
                                    />
                                    <SegmentControl
                                        selected={dashboardState.selectedDocumentType === 2}
                                        label={"Dipendenti"}
                                        onClick={() => {
                                            dispatch(setFindExtensionsFilesStatus("idle"))
                                            dispatch(setDocumentFilterType(DocumentType.employees))
                                            dispatch(setDocumentFilterItemsPerPage(5))
                                            dispatch(setFindAllDocumentsStatus("idle"))
                                            dispatch(setSelectedDocumentType(2))
                                            dispatch(findAllDocuments({
                                                companyId: null,
                                                itemsPerPage: 20,
                                                order: false,
                                                page: 0,
                                                receiverGroup: "",
                                                referenceYear: 0,
                                                referenceMonth: 0,
                                                senderGroup: "operators",
                                                sort: "",
                                                type: DocumentType.employees,
                                                typology: "",
                                                fileName: "",
                                                status: ""
                                            }))
                                        }}
                                    />
                                    <SegmentControl
                                        selected={dashboardState.selectedDocumentType === 3}
                                        label={"Altro"}
                                        onClick={() => {
                                            dispatch(setFindExtensionsFilesStatus("idle"))
                                            dispatch(setDocumentFilterType(DocumentType.other))
                                            dispatch(setDocumentFilterItemsPerPage(5))
                                            dispatch(setFindAllDocumentsStatus("idle"))
                                            dispatch(setSelectedDocumentType(3))
                                            dispatch(findAllDocuments({
                                                companyId: null,
                                                itemsPerPage: 20,
                                                order: false,
                                                page: 0,
                                                receiverGroup: "",
                                                referenceYear: 0,
                                                referenceMonth: 0,
                                                senderGroup: "operators",
                                                sort: "",
                                                type: DocumentType.other,
                                                typology: "",
                                                fileName: "",
                                                status: ""
                                            }))
                                        }}
                                    />
                                    <SegmentControl
                                        selected={dashboardState.selectedDocumentType === 4}
                                        label={"Contabili"}
                                        onClick={() => {
                                            dispatch(setFindExtensionsFilesStatus("idle"))
                                            dispatch(setDocumentFilterType(DocumentType.accounting))
                                            dispatch(setDocumentFilterItemsPerPage(5))
                                            dispatch(setFindAllDocumentsStatus("idle"))
                                            dispatch(setSelectedDocumentType(4))
                                            dispatch(findAllDocuments({
                                                companyId: null,
                                                itemsPerPage: 20,
                                                order: false,
                                                page: 0,
                                                receiverGroup: "",
                                                referenceYear: 0,
                                                referenceMonth: 0,
                                                senderGroup: "operators",
                                                sort: "",
                                                type: DocumentType.accounting,
                                                typology: "",
                                                fileName: "",
                                                status: ""
                                            }))
                                        }}
                                    />
                                </div>
                                <div className="flex flex-col rounded-2xl gap-3 flex-grow">
                                    {
                                        (documentState.findAllDocumentsStatus === "loading" ||
                                            documentState.findExtensionsFilesStatus === "loading") ?
                                            <div className="flex justify-center items-center flex-grow">
                                                <SpinnerComponent />
                                            </div>
                                            :
                                            documentState.findAllDocumentsStatus === "successfully" && documentRow.length === 0 ?
                                                <div className="flex justify-center items-center flex-grow">
                                                    <EmptyList />
                                                </div>
                                                :
                                                <div className="w-full flex flex-col gap-2 max-h-[430px] overflow-y-auto">
                                                    {documentRow}
                                                </div>
                                    }
                                </div>
                            </div>
                            <div className=' flex flex-col basis-[50%] gap-4'>
                                <StatsCardAlt onClick={() => navigate('/company')} title={'Ultimi messaggi ricevuti'}
                                    content={
                                        chatState.findChatsByLoggedUserStatus === 'loading' ?
                                            <SpinnerComponent /> :
                                            chatState.findChatByLoggedUserResponse.filter( item => item.lastMessageDate !== null).length ?
                                                chatState.findChatByLoggedUserResponse.
                                                    filter((element) => element.lastMessageDate !== null).
                                                    sort((a, b) => new Date(b.lastMessageDate).getTime() - new Date(a.lastMessageDate).getTime()).
                                                    slice(0, 3).map(
                                                        (item, index) =>
                                                            <div onClick={() => {
                                                                navigate('/operation-company/' + item.topic)
                                                                dispatch(setSelectedOperationTab(2))
                                                            }}
                                                                className=' flex flex-row hover:bg-neutral-100 cursor-pointer justify-between items-center w-full border-b border-brandSecondary-200 py-[6px] gap-4'>
                                                                <div className=' basis-[30%] flex  gap-1 '>
                                                                    <span className='text-text-sm text-brandSecondary-800 font-semibold'>{
                                                                        companyState.findAllCompanyResponse && companyState.findAllCompanyResponse!.data.
                                                                            find(el => el.id.toString() === item.topic)!.companyName}
                                                                    </span>
                                                                    {companyState.findAllCompanyResponse &&
                                                                        item.users.includes(companyState.findAllCompanyResponse.data.
                                                                            find(el => el.id.toString() === item.topic)!.userId) ?
                                                                        <CompanyIcon size={'20'} color={layoutState.theme[500]} /> :
                                                                        <OperatorIcon size={'20'} color={layoutState.theme[500]} />
                                                                    }
                                                                </div>
                                                                <div className=' basis-[70%] h-[18px] overflow-clip flex gap-2 justify-start'>
                                                                    <span className=' text-text-sm text-brandSecondary-500'>
                                                                        {chatState.findLastMessageResponse &&
                                                                            chatState.findLastMessageResponse[index].messages.data[0].
                                                                                message.substring(0, 70).concat('...')
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                    ) : <EmptyList />
                                    }
                                    label={'Vai alla sezione'} />
                                <StatsCardAlt title={'Avvisi di proroga F24'} content={documentState.findAllDocumentsStatus !== "loading" ? noticeF24.length ?
                                    (noticeF24).slice(0, 3).map(item =>
                                        <div onClick={() => {
                                            dispatch(setSelectedOperationTab(1))
                                            dispatch(setFindAllDocumentsStatus("idle"))
                                            dispatch(setDocumentFilterCompanyId(item.companyId))
                                            dispatch(setDocumentFilterPage(0))
                                            dispatch(setDocumentFilterFileName(""))
                                            dispatch(setDocumentFilterMonth(0))
                                            dispatch(setDocumentFilterTypology(""))
                                            dispatch(setDocumentFilterYear(0))
                                            dispatch(setDocumentFilterType(DocumentType.f24))
                                            dispatch(setDocumentFilterReceivedGroup(""))
                                            dispatch(setDocumentFilterSenderGroup(""))
                                            dispatch(setDocumentFilterStatus(""))
                                            dispatch(setSelectedDocumentTab(1))
                                            dispatch(findAllDocuments(documentState.documentFilters))
                                            navigate('operation-company/' + item.companyId);
                                        }} className=" flex flex-row justify-between border-b border-brandPrimary-100 py-[6px] gap-4 cursor-pointer">
                                            <span className=' text-brandSecondary-850 font-semibold'>
                                                {companyState.findAllCompanyResponse?.data.find(element =>
                                                    element.id.toString() === item.companyId.toString())?.companyName}
                                            </span>
                                            <span
                                                className=' text-brandSecondary-500 hover:bg-neutral-100'>{item.fileName + "  >"}
                                            </span>
                                        </div>
                                    ) : <EmptyList /> :
                                    <div className=' flex justify-center items-center'>
                                        <SpinnerComponent />
                                    </div>
                                }
                                    label={'Vai alla sezione'} onClick={() => navigate('/company')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    )
}

//