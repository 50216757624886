import React, { useEffect, useState } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { findFileById, setFindDocumentStatus } from "../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { toBase64 } from "../../lib/utilis/index.ts";
import { colors } from "../../ui/colors.ts";
import { EmailIcon } from "../../ui/icons/emailIcon.tsx";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { FileInputComponent } from "../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { FormRow } from "../../ui/organisms/formRow/formRow.tsx";
import { findMeCompany, findMeReferrer, setFindMeCompanyStatus, setFindMeReferrerStatus } from "../auth/slice.ts";
import { CompanyStatus } from "./dto.ts";
import { setEditReferrersEmail, setEditReferrersName, setEditReferrersNote, setEditReferrersSurname, setEditRefferrersAvatar } from "./referrers/slice.ts";
import { findByIdCompany, setEditCompanyAvatar, setEditCompanyRequestCompanyName, setEditCompanyRequestName, setEditCompanyRequestNote, setEditCompanyRequestSurname } from "./slice.ts";
export function RegistryCompany() {
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const referrersState = useAppSelector(state => state.referrers)
    const objectState = useAppSelector(state => state.object)

    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === "successfully") {
            dispatch(findFileById(companyState.findByIdCompanyResponse?.avatarObjectId!))
            dispatch(setEditCompanyRequestName(companyState.findByIdCompanyResponse?.name))
            dispatch(setEditCompanyRequestSurname(companyState.findByIdCompanyResponse?.surname))
            dispatch(setEditCompanyRequestCompanyName(companyState.findByIdCompanyResponse?.companyName))
            dispatch(setEditCompanyRequestNote(companyState.findByIdCompanyResponse?.note))
        }
    }, [companyState.findByIdCompanyStatus])

    useEffect(() => {
        if ((authState.findMeReferrersResponse || authState.findMeCompanyResponse) && companyState.selectedSettingTab === 0)
            setAvatarToBase64(objectState.document!)
    }, [companyState.selectedSettingTab])

    useEffect(() => {
        if (keycloak.hasRealmRole("company") && authState.findMeCompanyStatus === "successfully") {
            dispatch(findFileById(authState.findMeCompanyResponse?.avatarObjectId!))
            dispatch(setEditCompanyRequestName(authState.findMeCompanyResponse?.name))
            dispatch(setEditCompanyRequestSurname(authState.findMeCompanyResponse?.surname))
            dispatch(setEditCompanyRequestCompanyName(authState.findMeCompanyResponse?.companyName))
            dispatch(setEditCompanyRequestNote(authState.findMeCompanyResponse?.note))
        } else if (keycloak.hasRealmRole("administrative_company") && authState.findMeReferrersStatus === "successfully") {
            dispatch(findFileById(authState.findMeReferrersResponse?.avatarObjectId!))
            dispatch(setEditReferrersName(authState.findMeReferrersResponse?.name))
            dispatch(setEditReferrersSurname(authState.findMeReferrersResponse?.surname))
            dispatch(setEditReferrersEmail(authState.findMeReferrersResponse?.email))
            dispatch(setEditCompanyRequestNote(authState.findMeReferrersResponse?.note))
        }
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (companyState.editCompanyStatus === "successfully" && keycloak.hasRealmRole("company")) {
            dispatch(findMeCompany())
        } else if (!keycloak.hasRealmRole("company") && companyState.editCompanyStatus === "successfully") {
            dispatch(findByIdCompany(companyState.companyId))
        }
    }, [companyState.editCompanyStatus, keycloak.hasRealmRole])

    useEffect(() => {
        if (authState.findMeCompanyStatus === "successfully") {
            dispatch(setFindMeCompanyStatus("idle"))
        }
    }, [authState.findMeCompanyStatus])

    useEffect(() => {
        if (referrersState.editReferrersStatus === "successfully" && keycloak.hasRealmRole("administrative_company")) {
            dispatch(findMeReferrer())
        }
    }, [referrersState.editReferrersStatus, keycloak.hasRealmRole])

    useEffect(() => {
        if (authState.findMeReferrersStatus === "successfully") {
            dispatch(setFindMeReferrerStatus("idle"))
        }
    }, [authState.findMeReferrersStatus])

    useEffect(() => {
        if (objectState.findDocumentStatus === "successfully") {
            setAvatarToBase64(objectState.document!)
            dispatch(setFindDocumentStatus('idle'))
        }
    }, [objectState.findDocumentStatus])

    if (companyState.findByIdCompanyStatus === "loading" ||
        authState.findMeCompanyStatus === "loading" ||
        companyState.editCompanyStatus === "loading" ||
        authState.findMeReferrersStatus === "loading" ||
        referrersState.editReferrersStatus === "loading" ||
        objectState.findDocumentStatus === "loading"
    ) {
        return (
            <div className={"flex justify-center items-center"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className={"flex flex-col gap-5"}>
            {
                !keycloak.hasRealmRole("administrative_company") &&
                <FormRow
                    label="Ragione sociale e P.IVA"
                    children={[
                        <>
                            <InputComponent
                                type={"text"}
                                placeholder="Ragione sociale"
                                value={companyState.editCompanyRequest.companyName}
                                onChangeText={(value) => dispatch(setEditCompanyRequestCompanyName(value))}
                                disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                    (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                    (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                    (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                    (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                }
                            />
                            <InputComponent
                                type={"text"}
                                placeholder="Partita iva"
                                value={companyState.findByIdCompanyResponse?.vat ?? authState.findMeCompanyResponse?.vat}
                                disabled
                            />
                        </>
                    ]}
                />
            }
            <FormRow
                label={keycloak.hasRealmRole("administrative_company") ? "Referente" : "Nome"}
                children={[
                    <>
                        <InputComponent
                            type={"text"}
                            placeholder="Nome"
                            value={!keycloak.hasRealmRole("administrative_company") ? companyState.editCompanyRequest.name : referrersState.editReferrersRequest.name}
                            onChangeText={(value) => {
                                if (!keycloak.hasRealmRole("administrative_company"))
                                    dispatch(setEditCompanyRequestName(value))
                                else
                                    dispatch(setEditReferrersName(value))
                            }}
                            disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                            }
                        />
                        <InputComponent
                            type={"text"}
                            placeholder="Cognome"
                            value={!keycloak.hasRealmRole("administrative_company") ? companyState.editCompanyRequest.surname : referrersState.editReferrersRequest.surname}
                            onChangeText={(value) => {
                                if (!keycloak.hasRealmRole("administrative_company"))
                                    dispatch(setEditCompanyRequestSurname(value))
                                else
                                    dispatch(setEditReferrersSurname(value))
                            }}
                            disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                            }
                        />
                    </>
                ]}
            />
            <FormRow
                label="Email"
                children={[
                    <InputComponent
                        type={"text"}
                        placeholder="Email"
                        startIcon={<EmailIcon size={"20"} color={colors.neutral[400]} />}
                        value={keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.email : keycloak.hasRealmRole("administrative_company") ? authState.findMeReferrersResponse?.email : companyState.findByIdCompanyResponse?.email}
                        disabled
                    />
                ]}
            />
            <FormRow
                label="Avatar"
                subLabel="Verrà visualizzata sul vostro profilo"
                children={[
                    <div key={"profile-edit-avatar-upload"} className={"flex items-center justify-center gap-4 w-full"}>
                        <Avatar
                            type="single"
                            size="2xl"
                            shape="circle"
                            imageUrl={avatarToBase64}
                            altTextInitials={keycloak.hasRealmRole("company") ? (companyState.editCompanyRequest.name[0] || 'A') + (companyState.editCompanyRequest.surname[0] || 'A') : (referrersState.editReferrersRequest.name[0] || 'A') + (referrersState.editReferrersRequest.surname[0] || 'A')}
                        />
                        <FileInputComponent
                            id={"profile-edit-upload"}
                            key={"profile-edit-upload"}
                            infoText
                            iconSize={"32"}
                            color={"gray"}
                            iconBackground={colors.neutral[100]}
                            onChange={(e) => {
                                if (e.length) {
                                    if (!keycloak.hasRealmRole("administrative_company")) {
                                        dispatch(setEditCompanyAvatar(e[0]))
                                        toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                                    } else {
                                        dispatch(setEditRefferrersAvatar(e[0]))
                                        toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                                    }
                                }
                            }}
                            disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                                (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                                (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                            }
                        />
                    </div>
                ]}
            />
            <FormRow
                label="Note"
                subLabel="Inserisci le note dell'utente"
                children={[
                    <InputComponent
                        type={"text"}
                        placeholder="Un po' di informazioni su di te"
                        supportingText={275 - companyState.editCompanyRequest.note.length + " caratteri rimasti"}
                        multiple
                        defaultValue={keycloak.hasRealmRole("administrative_company") ? authState.findMeReferrersResponse?.note : keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.note : companyState.findByIdCompanyResponse?.note}
                        onChangeText={(value) => {
                            if (keycloak.hasRealmRole("administrative_company"))
                                dispatch(setEditReferrersNote(value))
                            else
                                dispatch(setEditCompanyRequestNote(value))
                        }}
                        disabled={companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED || (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                            (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                            (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                            (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                        }
                    />
                ]}
            />
        </div>
    )
}