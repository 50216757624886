import { ChatDTO, MessageFilterDTO, MessageResponse, TopicUnseenInfoDTO } from "./dto.ts";
import { ChatServiceImpl } from "./serviceImpl.ts";


export interface ChatService {
    findChats(topic: string): Promise<ChatDTO[]>
    findChatsByLoggedUser() : Promise<ChatDTO[]>
    findMessages(chatId: number, filter: MessageFilterDTO): Promise<MessageResponse>
    setChatSeen(chatId: number): Promise<void>
    unSeenMessages(topic: string): Promise<TopicUnseenInfoDTO[]>
}

export function NewChatService(): ChatService {
    return new ChatServiceImpl();
}