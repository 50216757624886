import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { keycloak } from "../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { colors } from "../../ui/colors.ts";
import { AccountantIcon } from "../../ui/icons/accountantIcon.tsx";
import { BalanceIcon } from "../../ui/icons/balanceIcon.tsx";
import { CompanyIcon } from "../../ui/icons/companyIcon.tsx";
import { DocumentIcon } from "../../ui/icons/documentIcon.tsx";
import { EmployeeIcon } from "../../ui/icons/employeeIcon.tsx";
import { F24Icon } from "../../ui/icons/f24Icon.tsx";
import { OtherIcon } from "../../ui/icons/otherIcon.tsx";
import { SettingIcon } from "../../ui/icons/settingIcon.tsx";
import { LayoutComponent } from "../../ui/layout/index.tsx";
import { ButtonComponent } from "../../ui/molecules/button/index.tsx";
import { Pills } from "../../ui/molecules/pills/index.tsx";
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx";
import { HorizontalTab } from "../../ui/organisms/horizontalTab/index.tsx";
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts";
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx";
import { Tab } from "../../ui/organisms/tab/index.tsx";
import { BankAccount } from "./bankAccount/index.tsx";
import { Chat } from "./chat/index.tsx";
import { setFindChatsStatus, setSelectedChat, unSeenMessages } from "./chat/slice.ts";
import { Accountant } from "./documents/accountant/accountant.tsx";
import { Balance } from "./documents/balance/balace.tsx";
import { DocumentType } from "./documents/dto.ts";
import { Employee } from "./documents/employee/employee.tsx";
import { F24 } from "./documents/f24/f24.tsx";
import { Documents } from "./documents/index.tsx";
import { Other } from "./documents/other/other.tsx";
import { setDeleteDocumentStatus, setDocumentFilterCompanyId, setDocumentFilterFileName, setDocumentFilterMonth, setDocumentFilterPage, setDocumentFilterReceivedGroup, setDocumentFilterSenderGroup, setDocumentFilterStatus, setDocumentFilterType, setDocumentFilterTypology, setDocumentFilterYear, setFindAllDocumentsStatus, setSaveDocumentStatus, setUpdateF24StatusStatus } from "./documents/slice.ts";
import { CompanyStatus, companyStatusMap, tabOperationCompanyMap, tabOperationMap } from "./dto.ts";
import { Events } from "./events/index.tsx";
import { Overview } from "./overview.tsx";
import { setReferrersFiltersCompanyId } from "./referrers/slice.ts";
import { findByIdCompany, setSelectedCompanyDocumentTab, setSelectedDocumentTab, setSelectedOperationTab, setSelectedSettingTab } from "./slice.ts";
import { setEventFilterCompanyId } from "./events/slice.ts";

export function OperationCompanyView() {
    const navigation = useNavigate()
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)
    const documentState = useAppSelector(state => state.document)
    const chatState = useAppSelector(state => state.chat)

    useEffect(() => {
        dispatch(setFindChatsStatus("idle"))
        dispatch(setSelectedChat(0))
    }, [companyState.selectedOperationTab])

    useEffect(() => {
        if (!keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company"))
            dispatch(findByIdCompany(companyState.companyId))
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === "successfully" && !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company")) {
            dispatch(setReferrersFiltersCompanyId(companyState.findByIdCompanyResponse?.id))
            dispatch(setDocumentFilterCompanyId(companyState.findByIdCompanyResponse?.id))
            dispatch(setEventFilterCompanyId(companyState.findByIdCompanyResponse?.id))
            dispatch(unSeenMessages(companyState.findByIdCompanyResponse?.id!))
        }
    }, [companyState.findByIdCompanyStatus, keycloak.hasRealmRole])

    const viewComponentMap = new Map<number, ReactElement>([
        [0, <Overview />],
        [1, <Documents />],
        [2, <Chat />],
        [3, <BankAccount />],
        [4, <Events />],
    ])

    const viewCompanyComponentMap = new Map<number, ReactElement>([
        [0, <Balance />],
        [1, <F24 />],
        [2, <Employee />],
        [3, <Accountant />],
        [4, <Other />],
    ])

    return (
        <LayoutComponent
            menuItem={!keycloak.hasRealmRole('company') && !keycloak.hasRealmRole("administrative_company") ? MenuItems.COMPANY : window.location.pathname === '/document-company' ? MenuItems.DOCUMENT : MenuItems.SETTING}
            headingLabel={
                companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED
                    ? (
                        <div className="flex flex-row items-center gap-2">
                            {companyState.findByIdCompanyResponse.companyName}
                            <Pills size={"sm"} label={companyStatusMap.get(companyState.findByIdCompanyResponse.status)!} outline={false} emphasis={false} color={"orange"} />
                        </div>
                    )
                    : companyState.findByIdCompanyResponse?.companyName! ?? "Documenti"
            }
            headingButtons={[
                <>
                    {
                        !keycloak.hasRealmRole('company') && !keycloak.hasRealmRole("administrative_company") &&
                        <div className="flex">
                            <ButtonComponent
                                label={"Impostazioni"}
                                onClick={() => {
                                    dispatch(setSelectedSettingTab(0));
                                    navigation('/settings-company/' + companyState.findByIdCompanyResponse?.id)
                                }}
                                color={"gray"}
                                variant={"outline"}
                                size={"md"}
                                icon={<SettingIcon color={colors.neutral[500]} size="20" />}
                                iconPosition={"left"}
                            />
                        </div>
                    }
                </>
            ]}
            breadcrumbItems={!keycloak.hasRealmRole('company') && !keycloak.hasRealmRole("administrative_company") ? tabOperationMap[companyState.selectedOperationTab] : tabOperationCompanyMap[companyState.selectedCompanyDocumentTab]}
            breadcrumbIcon={!keycloak.hasRealmRole('company') && !keycloak.hasRealmRole("administrative_company") ? <CompanyIcon size={"20"} color={colors.brandSecondary[500]} /> : <DocumentIcon size={"20"} color={colors.brandSecondary[500]} />}
        >
            <ErrorPopup
                active={documentState.saveDocumentStatus === "failed"}
                close={() => dispatch(setSaveDocumentStatus('idle'))}
                message="Si è verificato un errore durante il salvaggio del documento."
            />
            <SuccessPopup
                active={documentState.saveDocumentStatus === "successfully"}
                close={() => dispatch(setSaveDocumentStatus('idle'))}
                message="Documento salvato correttamente."
            />
            <ErrorPopup
                active={documentState.deleteDocumentStatus === "failed"}
                close={() => dispatch(setDeleteDocumentStatus('idle'))}
                message="Si è verificato un errore durante l'eliminazione del documento."
            />
            <SuccessPopup
                active={documentState.deleteDocumentStatus === "successfully"}
                close={() => dispatch(setDeleteDocumentStatus('idle'))}
                message="Documento eliminato correttamente."
            />
            <ErrorPopup
                active={documentState.updateStatusF24Status === "failed"}
                close={() => dispatch(setUpdateF24StatusStatus('idle'))}
                message="Si è verificato un errore durante l'aggiornamento del documento."
            />
            <SuccessPopup
                active={documentState.updateStatusF24Status === "successfully"}
                close={() => dispatch(setUpdateF24StatusStatus('idle'))}
                message="Stato del documento aggiornato correttamente."
            />
            {
                !keycloak.hasRealmRole('company') && !keycloak.hasRealmRole("administrative_company") ?
                    <HorizontalTab>
                        <Tab
                            label={"Overview"}
                            selectedPrimary={companyState.selectedOperationTab === 0}
                            onClick={() => dispatch(setSelectedOperationTab(0))}
                            type="primary"
                        />
                        <Tab
                            label={"Documenti"}
                            selectedPrimary={companyState.selectedOperationTab === 1}
                            onClick={() => {
                                dispatch(setSelectedOperationTab(1))
                                dispatch(setSelectedDocumentTab(0))
                            }}
                            type="primary"
                        />
                        <Tab
                            label={"Messaggi"}
                            selectedPrimary={companyState.selectedOperationTab === 2}
                            onClick={() => dispatch(setSelectedOperationTab(2))}
                            type="primary"
                            labelBadge={chatState.unSeenMessagesResponse?.find(unSeen => unSeen.unseen)?.unseen}
                        />
                        <Tab
                            label={"Conto corrente"}
                            selectedPrimary={companyState.selectedOperationTab === 3}
                            onClick={() => dispatch(setSelectedOperationTab(3))}
                            type="primary"
                        />
                        <Tab
                            label={"Calendario"}
                            selectedPrimary={companyState.selectedOperationTab === 4}
                            onClick={() => dispatch(setSelectedOperationTab(4))}
                            type="primary"
                        />
                    </HorizontalTab>
                    :
                    <HorizontalTab>
                        <Tab
                            icon={<BalanceIcon size={"20"} color={""} />}
                            label={"Bilanci e Dichiarativi"}
                            selectedPrimary={companyState.selectedCompanyDocumentTab === 0}
                            onClick={() => {
                                dispatch(setFindAllDocumentsStatus("idle"))
                                dispatch(setDocumentFilterPage(0))
                                dispatch(setDocumentFilterFileName(""))
                                dispatch(setDocumentFilterMonth(0))
                                dispatch(setDocumentFilterTypology(""))
                                dispatch(setDocumentFilterYear(0))
                                dispatch(setDocumentFilterType(DocumentType.balance))
                                dispatch(setDocumentFilterReceivedGroup(""))
                                dispatch(setDocumentFilterSenderGroup(""))
                                dispatch(setDocumentFilterStatus(""))
                                dispatch(setSelectedCompanyDocumentTab(0))
                            }}
                            type="primary"
                        />
                        <Tab
                            icon={<F24Icon size={"20"} color={""} />}
                            label={"F24"}
                            selectedPrimary={companyState.selectedCompanyDocumentTab === 1}
                            onClick={() => {
                                dispatch(setFindAllDocumentsStatus("idle"))
                                dispatch(setDocumentFilterPage(0))
                                dispatch(setDocumentFilterFileName(""))
                                dispatch(setDocumentFilterMonth(0))
                                dispatch(setDocumentFilterTypology(""))
                                dispatch(setDocumentFilterYear(0))
                                dispatch(setDocumentFilterType(DocumentType.f24))
                                dispatch(setDocumentFilterReceivedGroup(""))
                                dispatch(setDocumentFilterSenderGroup(""))
                                dispatch(setDocumentFilterStatus(""))
                                dispatch(setSelectedCompanyDocumentTab(1))
                            }}
                            type="primary"
                        />
                        <Tab
                            icon={<EmployeeIcon size={"20"} color={""} />}
                            label={"Dipendenti"}
                            selectedPrimary={companyState.selectedCompanyDocumentTab === 2}
                            onClick={() => {
                                dispatch(setFindAllDocumentsStatus("idle"))
                                dispatch(setDocumentFilterPage(0))
                                dispatch(setDocumentFilterFileName(""))
                                dispatch(setDocumentFilterMonth(0))
                                dispatch(setDocumentFilterTypology(""))
                                dispatch(setDocumentFilterYear(0))
                                dispatch(setDocumentFilterType(DocumentType.employees))
                                dispatch(setDocumentFilterReceivedGroup(""))
                                dispatch(setDocumentFilterSenderGroup(""))
                                dispatch(setDocumentFilterStatus(""))
                                dispatch(setSelectedCompanyDocumentTab(2))
                            }}
                            type="primary"
                        />
                        <Tab
                            icon={<OtherIcon size={"20"} color={""} />}
                            label={"Altro"}
                            selectedPrimary={companyState.selectedCompanyDocumentTab === 4}
                            onClick={() => {
                                dispatch(setFindAllDocumentsStatus("idle"))
                                dispatch(setDocumentFilterPage(0))
                                dispatch(setDocumentFilterFileName(""))
                                dispatch(setDocumentFilterMonth(0))
                                dispatch(setDocumentFilterTypology(""))
                                dispatch(setDocumentFilterYear(0))
                                dispatch(setDocumentFilterType(DocumentType.other))
                                dispatch(setDocumentFilterReceivedGroup(""))
                                dispatch(setDocumentFilterSenderGroup(""))
                                dispatch(setDocumentFilterStatus(""))
                                dispatch(setSelectedCompanyDocumentTab(4))
                            }}
                            type="primary"
                        />
                        <div className="border border-neutral-200" />
                        <Tab
                            icon={<AccountantIcon size={"20"} color={""} />}
                            label={"Contabili"}
                            selectedPrimary={companyState.selectedCompanyDocumentTab === 3}
                            onClick={() => {
                                dispatch(setFindAllDocumentsStatus("idle"))
                                dispatch(setDocumentFilterPage(0))
                                dispatch(setDocumentFilterFileName(""))
                                dispatch(setDocumentFilterMonth(0))
                                dispatch(setDocumentFilterTypology(""))
                                dispatch(setDocumentFilterYear(0))
                                dispatch(setDocumentFilterType(DocumentType.accounting))
                                dispatch(setDocumentFilterReceivedGroup(""))
                                dispatch(setDocumentFilterStatus(""))
                                dispatch(setDocumentFilterSenderGroup("companies"))
                                dispatch(setSelectedCompanyDocumentTab(3))
                            }}
                            type="primary"
                        />
                    </HorizontalTab>
            }
            {
                !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company") ?
                    viewComponentMap.get(companyState.selectedOperationTab) :
                    viewCompanyComponentMap.get(companyState.selectedCompanyDocumentTab)
            }
        </LayoutComponent >
    )
}