import React, { useEffect } from 'react'
import { ButtonComponent } from '../../../ui/molecules/button/index.tsx'
import { keycloak } from '../../../lib/keycloak/index.ts'
import { useAppDispatch } from '../../../lib/redux/hook.ts'
import { resetSelectPlanStripeStatus, setCreateNewSpontaneousStatus, setPromoCode } from './slice.ts'
import { TickIcon } from '../../../ui/icons/tickIcon.tsx'

export function SuccesfulSignup() {
    const dispatch = useAppDispatch()

     useEffect(()=>{
        dispatch(resetSelectPlanStripeStatus())
        dispatch(setCreateNewSpontaneousStatus('idle'))
        dispatch(setPromoCode(''))
     },[])

    return (
        <div>
        <div className=' flex flex-col items-center gap-10'>
            <h1 className='font-bold text-heading-xl w-[80%] text-center text-neutral-850 '>Operazione completata con successo!</h1>
            <h2 className='text-neutral-500 text-text-lg w-[80%]  font-medium text-center '>Per la tua prima sottoscrizione, controlla la tua email e
                imposta la password per iniziare.
            </h2>
            <div className=' bg-green-500 rounded-full flex justify-center items-center gap-2 p-4'>
              <TickIcon color='white' size='32' />
            </div>
            <h2 className='text-neutral-500 text-text-lg font-medium text-center '>Se hai rinnovato, torna alla home
                e continua a esplorare.
            </h2>
            <ButtonComponent onClick={()=>{ keycloak.login() }} label='Vai alla home' size={'lg'} iconPosition={'off'} variant={'link'} color={'blue'}/>
        </div>
        </div>
    )
}
