import React, { useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { colors } from "../../../ui/colors.ts";
import { EditIcon } from "../../../ui/icons/editIcon.tsx";
import { TrashIcon } from "../../../ui/icons/trashIcon.tsx";
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../../ui/organisms/dropDown/index.tsx";
import { InfoTextPopup } from "../../../ui/organisms/infoTextPopup/index.tsx";
import { CompanyStatus } from "../dto.ts";
import { deleteReferrer, findAllReferrers, findByIdReferrer, resetCreateReferrersRequest, setNewOrEditReferrersModal, setOpenConfirmModal, setSaveOrEditModal } from "./slice.ts";

export function ReferrersList() {
    const dispatch = useAppDispatch()
    const referrersState = useAppSelector(state => state.referrers)
    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)

    const [isDelete, setIsDelete] = useState<string | undefined>()

    useEffect(() => {
        dispatch(findAllReferrers(referrersState.referrersFilter))
    }, [])

    useEffect(() => {
        if (referrersState.createReferrersStatus === "successfully") {
            dispatch(findAllReferrers(referrersState.referrersFilter))
            dispatch(resetCreateReferrersRequest())
        }
    }, [referrersState.createReferrersStatus])

    useEffect(() => {
        if (referrersState.createReferrersStatus === "failed") {
            dispatch(resetCreateReferrersRequest())
        }
    }, [referrersState.createReferrersStatus])

    useEffect(() => {
        if (referrersState.editReferrersStatus === "successfully") {
            dispatch(findAllReferrers(referrersState.referrersFilter))
        }
    }, [referrersState.editReferrersStatus])

    useEffect(() => {
        if (referrersState.deleteReferrerStatus === "successfully") {
            dispatch(findAllReferrers(referrersState.referrersFilter))
        }
    }, [referrersState.deleteReferrerStatus])

    if (referrersState.findAllReferrersStatus === "loading" ||
        referrersState.createReferrersStatus === "loading" ||
        referrersState.editReferrersStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className="w-full flex flex-col gap-4 rounded-tr-xl rounded-tl-xl overflow-auto">
            <div>
                <table className="w-full mb-3">
                    <thead className="h-[56px] bg-neutral-100 font-normal text-neutral-500 text-text-xs">
                        <th className="text-left">
                            <div className="flex py-4 px-4 gap-1">
                                REFERENTI
                            </div>
                        </th>
                        {
                            referrersState.findAllReferrersResponse?.data.filter(id => id.id !== authState.findMeReferrersResponse?.id)?.length !== 0 &&
                            <>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 gap-1">
                                        EMAIL
                                    </div>
                                </th>
                                <th className="text-left">
                                    <div className="flex py-4 px-2 gap-1" />
                                </th>
                            </>
                        }
                    </thead>
                    <tbody>
                        {
                             referrersState.findAllReferrersResponse?.data.filter(id => id.id !== authState.findMeReferrersResponse?.id)?.length === 0 ?
                                <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                    <td className="py-4 px-4 text-text-sm font-medium text-neutral-500">
                                        Nessun referente trovato!
                                    </td>
                                </tr>
                                :
                                referrersState.findAllReferrersResponse?.data.filter(id => id.id !== authState.findMeReferrersResponse?.id).map((item) =>
                                    <tr
                                        className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                                        key={""}
                                    >
                                        <td className="px-4 py-4 text-text-sm font-semibold text-neutral-850">{(item.name + " " + item.surname).length <= 25 ? (item.name + " " + item.surname) : (item.name + " " + item.surname).substring(0, 25).concat('...')}</td>
                                        <td className="px-2 py-4 text-text-sm text-neutral-600">{item.email.length <= 25 ? item.email : item.email.substring(0, 20).concat('...')}</td>
                                        <td className="h-[56px] py-4 px-2 flex flex-row justify-end items-center">
                                            {
                                                !keycloak.hasRealmRole("financial_advisor") &&
                                                !keycloak.hasRealmRole("financial_advisor_collaborator") &&
                                                !keycloak.hasRealmRole("administrative_company") &&
                                                companyState.findByIdCompanyResponse?.status !== CompanyStatus.SUSPENDED &&
                                                (authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                                (authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company")) &&
                                                <div className="hover:bg-brandPrimary-50 py-2 px-2 rounded-md">
                                                    <InfoTextPopup
                                                        label={"Cancella"}
                                                        children={
                                                            <TrashIcon
                                                                size={"24"}
                                                                color={colors.brandSecondary[500]}
                                                                onClick={() => {
                                                                    setIsDelete(item.id)
                                                                    dispatch(setOpenConfirmModal(true))
                                                                }}
                                                            />
                                                        }
                                                        position={"bottom"}
                                                    />
                                                </div>
                                            }
                                            <Dropdown items={[
                                                {
                                                    label: 'Modifica',
                                                    icon: <EditIcon color="" size={""} />,
                                                    onClick: () => {
                                                        dispatch(setSaveOrEditModal('edit'))
                                                        dispatch(findByIdReferrer(item.id))
                                                        dispatch(setNewOrEditReferrersModal(true))
                                                    }
                                                },
                                                {
                                                    label: 'Cancella',
                                                    icon: <TrashIcon color="" size={""} />,
                                                    onClick: () => {
                                                        setIsDelete(item.id)
                                                        dispatch(setOpenConfirmModal(true))
                                                    },
                                                    visible: keycloak.hasRealmRole("financial_advisor") ||
                                                        keycloak.hasRealmRole("financial_advisor_collaborator") ||
                                                        keycloak.hasRealmRole("administrative_company") ||
                                                        companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                                                        (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                                                        (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                                                }
                                            ]} />
                                        </td>
                                    </tr>
                                )
                        }
                    </tbody>
                </table>
            </div>
            <ConfirmModal
                open={referrersState.openConfirmModal}
                handleClose={() => dispatch(setOpenConfirmModal(false))}
                title={"Elimina referente"}
                description={"Sei sicuro di voler eliminare questo referente?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteReferrer(isDelete))
                    dispatch(setOpenConfirmModal(false))
                }}
            />
        </div>
    )
}