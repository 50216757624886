import clsx from "clsx"
import React, { ReactNode } from "react"
import { useAppSelector } from "../../../lib/redux/hook.ts"
import { colors } from "../../colors.ts"
import { Radio } from "../../molecules/radio/index.tsx"
import { SpinnerComponent } from "../../molecules/spinner/index.tsx"
interface Props {
    name: string,
    price: string,
    onChange?: (value: boolean) => void,
    checked: boolean
    infoProduct: ReactNode
    isLoading: boolean
}
export function SubscriptionChoiceCard(props: Props) {
    const { name, price, onChange, checked, infoProduct, isLoading } = props

    const layoutState = useAppSelector(state => state.layout)

    return (
        <div style={{ borderColor: name === "Free" ? colors.green[500] : name === "Unlimited" ? layoutState.theme[300] : layoutState.theme[200], backgroundColor: checked ? layoutState.theme[50] : colors.white }}
            className={
                clsx('shadow-xs flex flex-col rounded-xl border w-full')}
        >
            <div className=" flex flex-col items-start justify-start w-full p-4 gap-2 flex-grow">
                <div className=" flex flex-row items-center justify-between w-full ">
                    <div className='font-bold text-text-lg text-brandSecondary-800'>{name}</div>
                    {
                        !isLoading &&
                        <Radio
                            onChange={(value) => onChange && onChange(value)}
                            isChecked={checked}
                            value={name}
                            size='sm'
                            radioGroup='subscription'
                        />
                    }
                </div>
                <div className='flex flex-row justify-center items-center gap-2'>
                    <span className='font-semibold text-heading-xl text-brandSecondary-800'>
                        {isLoading ? <SpinnerComponent size={20} /> : price}
                    </span>
                    <span className='font-medium text-text-md text-neutral-500'>€/mese</span>
                </div>
            </div>
            <div className='flex flex-col gap-1 px-4 pb-6 justify-center flex-grow'>
                {infoProduct}
            </div>
        </div>
    )
}