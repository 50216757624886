import React, { ReactNode, useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { EditIcon } from "../../../ui/icons/editIcon.tsx";
import { TrashIcon } from "../../../ui/icons/trashIcon.tsx";
import { Pills } from "../../../ui/molecules/pills/index.tsx";
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../../ui/organisms/dropDown/index.tsx";
import { FinancialAdvisorDTO } from "../dto.ts";
import { deleteCollaborator, findAllCollaborator, findByIdCollaborator, resetCreateCollaboratorsRequest, setOpenConfirmModal, setOpenNewCollaboratorModal, setSaveOrEdit } from "./slice.ts";

export function CollaboratorList() {
    const dispatch = useAppDispatch()

    const collaboratorState = useAppSelector(state => state.collaborator)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const authState = useAppSelector(state => state.auth)

    const [isDelete, setIsDelete] = useState<string | undefined>()

    useEffect(() => {
        dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
    }, [])

    useEffect(() => {
        dispatch(resetCreateCollaboratorsRequest())
    }, [collaboratorState.openNewCollaboratorModal])

    useEffect(() => {
        if (collaboratorState.createCollaboratorStatus === "successfully") {
            dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
            dispatch(resetCreateCollaboratorsRequest())
        }
    }, [collaboratorState.createCollaboratorStatus])

    useEffect(() => {
        if (collaboratorState.createCollaboratorStatus === "failed") {
            dispatch(resetCreateCollaboratorsRequest())
        }
    }, [collaboratorState.createCollaboratorStatus])

    useEffect(() => {
        if (collaboratorState.editCollaboratorStatus === "successfully") {
            dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
        }
    }, [collaboratorState.editCollaboratorStatus])

    useEffect(() => {
        if (collaboratorState.deleteCollaboratorStatus === "successfully") {
            dispatch(findAllCollaborator(collaboratorState.collaboratorFilters))
        }
    }, [collaboratorState.deleteCollaboratorStatus])

    if (collaboratorState.findAllCollaboratorsForFinancialAdvisorStatus === "loading" || collaboratorState.createCollaboratorStatus === "loading" || collaboratorState.deleteCollaboratorStatus === "loading" || collaboratorState.editCollaboratorStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }

    let financialAdvisor: FinancialAdvisorDTO | undefined
    let collaboratorRows: ReactNode[] = []

    if (collaboratorState.findAllCollaboratorStatus === "successfully" ||
        (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully" && keycloak.hasRealmRole("financial_advisor"))
    ) {
        collaboratorState.findAllCollaboratorResponse?.data.forEach((collaborator) => {
            financialAdvisor = !keycloak.hasRealmRole("financial_advisor") ? financialAdvisorState.findByIdFinancialAdvisorResponse : authState.findMeFinancialAdvisorResponse
            collaboratorRows.push(
                <tr
                    className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                    key={collaborator.id}
                >
                    <td className="px-4 py-4 text-text-sm font-semibold text-neutral-850">{(collaborator.name + " " + collaborator.surname).length <= 20 ? (collaborator.name + " " + collaborator.surname) : (collaborator.name + " " + collaborator.surname).substring(0, 20).concat('...')}</td>
                    <td className="px-2 py-4 text-text-sm text-neutral-600">{(financialAdvisor?.name + ' ' + financialAdvisor?.surname).length <= 20 ? (financialAdvisor?.name + ' ' + financialAdvisor?.surname) : (financialAdvisor?.name + ' ' + financialAdvisor?.surname).substring(0, 20).concat('...')}</td>
                    <td className="px-2 py-4 text-text-sm text-neutral-600">{collaborator.email.length <= 30 ? collaborator.email : collaborator.email.substring(0, 30).concat('...')}</td>
                    <td className="px-2 py-4 text-text-sm text-neutral-600">{collaborator.phoneNumber}</td>
                    <td className="py-[16px] px-[8px] text-text-sm font-semibold text-neutral-600 flex justify-center">
                        <Pills size={"sm"} label={collaborator.companies.length.toString() ?? ''} outline={false} emphasis={false} color={"gray"} />
                    </td>
                    <td className="w-[40px] cursor-pointer">
                        <Dropdown items={[
                            {
                                label: 'Modifica',
                                icon: <EditIcon color="" size={""} />,
                                onClick: () => {
                                    dispatch(setSaveOrEdit('edit'))
                                    dispatch(findByIdCollaborator(collaborator.id))
                                    dispatch(setOpenNewCollaboratorModal(true))
                                }
                            },
                            {
                                label: 'Cancella',
                                icon: <TrashIcon color="" size={""} />,
                                onClick: () => {
                                    setIsDelete(collaborator.id)
                                    dispatch(setOpenConfirmModal(true))
                                },
                                visible: (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                            }
                        ]} />
                    </td>
                </tr>
            )
        })
    }

    return (
        <div className="w-full flex flex-col gap-4 bg-neutral-100 rounded-tr-xl rounded-tl-xl overflow-auto">
            <div>
                <table className="w-full">
                    <thead className="h-[56px] font-normal text-neutral-500 text-text-xs">
                        <tr>
                            <th className="text-left">
                                <div className="flex py-4 px-4 gap-1">
                                    COLLABORATORI
                                </div>
                            </th>
                            {
                                collaboratorState.findAllCollaboratorResponse?.total !== 0 &&
                                <>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            COMMERCIALISTA
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            EMAIL
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            TELEFONO
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1 justify-center">
                                            AZIENDE
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1" />
                                    </th>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            collaboratorState.findAllCollaboratorResponse?.total === 0 ?
                                <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                    <td className="py-4 px-4 text-text-sm font-medium text-neutral-500">
                                        Nessun collaboratore trovato!
                                    </td>
                                </tr>
                                :
                                collaboratorRows
                        }
                    </tbody>
                </table>
            </div>
            <ConfirmModal
                open={collaboratorState.openConfirmModal}
                handleClose={() => dispatch(setOpenConfirmModal(false))}
                title={"Vuoi cancellare questo collaboratore?"}
                description={"Se l'utente è associato ad un'azienda non potrà più gestirla."}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteCollaborator(isDelete))
                    dispatch(setOpenConfirmModal(false))
                }}
            />
        </div>
    )
}