import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { EditIcon } from "../../ui/icons/editIcon.tsx";
import { TrashIcon } from "../../ui/icons/trashIcon.tsx";
import { Pills } from "../../ui/molecules/pills/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { ConfirmModal } from "../../ui/organisms/confirmModal/index.tsx";
import { Dropdown } from "../../ui/organisms/dropDown/index.tsx";
import { StripePaymentStatus, subscriptionPeriodLabelMap, SubscriptionTierLimit, subscriptionTierLimitMap, SubscriptionType, subscriptionTypeMap } from "./dto.ts";
import { deleteFinancialAdvisor, findAllFinancialAdvisor, resetCreateFinancialAdvisorRequest, setFinancialAdvisorId, setOpenConfirmModal } from "./slice.ts";

export function FinancialAdvisorList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)

    const [isDelete, setIsDelete] = useState<string | undefined>()

    useEffect(() => {
        dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
    }, [])

    useEffect(() => {
        if (financialAdvisorState.createFinancialAdvisorStatus === "successfully") {
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
            dispatch(resetCreateFinancialAdvisorRequest())
        }
    }, [financialAdvisorState.createFinancialAdvisorStatus])

    useEffect(() => {
        if (financialAdvisorState.createFinancialAdvisorStatus === "failed") {
            dispatch(resetCreateFinancialAdvisorRequest())
        }
    }, [financialAdvisorState.createFinancialAdvisorStatus])

    useEffect(() => {
        if (financialAdvisorState.deleteFinancialAdvisorStatus === "successfully") {
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        }
    }, [financialAdvisorState.deleteFinancialAdvisorStatus])

    useEffect(() => {
        if (financialAdvisorState.editFinancialAdvisorStatus === "successfully") {
            dispatch(findAllFinancialAdvisor(financialAdvisorState.findAllFinancialAdvisorFilters))
        }
    }, [financialAdvisorState.editFinancialAdvisorStatus])

    if (financialAdvisorState.findAllFinancialAdvisorStatus === "loading" ||
        financialAdvisorState.editFinancialAdvisorStatus === "loading" ||
        financialAdvisorState.deleteFinancialAdvisorStatus === "loading" ||
        financialAdvisorState.createFinancialAdvisorStatus === "loading") {
        return (
            <div className={"flex justify-center items-center flex-grow"}>
                <SpinnerComponent />
            </div>
        )
    }
    return (
        <div className="w-full flex flex-col gap-4 bg-neutral-100 rounded-tr-xl rounded-tl-xl rounded-b-lg overflow-auto">
            <div>
                <table className="w-full overflow-auto">
                    <thead className="h-[56px] font-semibold text-neutral-500 text-text-xs">
                        <tr>
                            <th className="text-left">
                                <div className="flex py-4 px-4 gap-1">
                                    RAGIONE SOCIALE
                                </div>
                            </th>
                            {
                                financialAdvisorState.findAllFinancialAdvisorResponse?.total !== 0 &&
                                <>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            COMMERCIALISTA
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            EMAIL
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            TELEFONO
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            PARTITA IVA
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            ABBONAMENTO
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            STATO
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1">
                                            SCADENZA
                                        </div>
                                    </th>
                                    <th className="text-center">
                                        <div className="flex py-4 px-2 gap-1 justify-center">
                                            AZIENDE
                                        </div>
                                    </th>
                                    <th className="text-left">
                                        <div className="flex py-4 px-2 gap-1" />
                                    </th>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            financialAdvisorState.findAllFinancialAdvisorResponse?.total === 0 ?
                                <tr className="bg-white h-[56px] border-b-[1px] border-b-neutral-100 w-full">
                                    <td className="py-4 px-4 text-text-sm font-medium text-neutral-500">
                                        Nessun commercialista
                                    </td>
                                </tr>
                                :
                                financialAdvisorState.findAllFinancialAdvisorResponse?.data.map(item =>
                                    <tr
                                        className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                                        key={item.id}
                                    >
                                        <td className="py-[16px] px-[16px] text-text-sm font-semibold text-neutral-850">{item.commercialName.length <= 20 ? item.commercialName : item.commercialName.slice(0, 20).concat('...')}</td>
                                        <td className="py-[16px] px-[8px] text-text-sm font-semibold text-neutral-600">{(item.name + ' ' + item.surname).length <= 20 ? (item.name + ' ' + item.surname) : (item.name + ' ' + item.surname).slice(0, 20).concat('...')}</td>
                                        <td className="py-[16px] px-[8px] text-text-sm font-semibold text-neutral-600">{item.email.length <= 30 ? item.email : item.email.slice(0, 30).concat('...')}</td>
                                        <td className="py-[16px] px-[8px] text-text-sm font-semibold text-neutral-600">{item.phoneNumber}</td>
                                        <td className="py-[16px] px-[8px] text-text-sm font-semibold text-neutral-600">{item.vat}</td>
                                        <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">
                                            <Pills
                                                size={"sm"}
                                                label={(item.paymentSuccess === null ? "-" :
                                                    item.paymentSuccess === StripePaymentStatus.Pending && !
                                                        item.firstTime ? 'Da attivare' :
                                                        (subscriptionTierLimitMap.get(item.subscriptionTierLimit)! +
                                                            " - " +
                                                            subscriptionPeriodLabelMap.get(item.subscriptionPeriod)!))}
                                                outline={false}
                                                emphasis={false}
                                                color={"blue"}
                                            />
                                        </td>
                                        <td className="py-4 px-2 text-text-sm font-semibold text-neutral-600">
                                            <Pills
                                                size={"sm"}
                                                label={item.paymentSuccess === StripePaymentStatus.Pending && !item.firstTime ?
                                                    'Da attivare' :
                                                    item.subscriptionTierLimit === SubscriptionTierLimit.free ? "Free" :
                                                        subscriptionTypeMap.get(item.subscriptionType)!
                                                }
                                                outline={false}
                                                emphasis={item.paymentSuccess === StripePaymentStatus.Pending && !item.firstTime
                                                    || item.subscriptionType === SubscriptionType.expired ? true : false
                                                }
                                                color={item.paymentSuccess === StripePaymentStatus.Pending &&
                                                    !item.firstTime ?
                                                    'orange' : item.subscriptionType === SubscriptionType.expired ?
                                                        "red" : item.subscriptionType === SubscriptionType.canceled ?
                                                            "orange" : "green"
                                                }
                                            />
                                        </td>
                                        <td className="py-[16px] px-[8px] text-text-sm font-semibold text-neutral-600">{format(new Date(item.subscriptionExpirationDate), 'dd/MM/yyyy')}</td>
                                        <td className="py-[16px] px-[8px] text-text-sm font-semibold text-neutral-600 flex justify-center">
                                            <Pills size={"sm"} label={item.companies.length.toString()} outline={false} emphasis={false} color={"gray"} />
                                        </td>
                                        <td className="w-[40px] cursor-pointer">
                                            <Dropdown items={[
                                                {
                                                    label: 'Modifica',
                                                    icon: <EditIcon color="" size={""} />,
                                                    onClick: () => {
                                                        dispatch(setFinancialAdvisorId(item.id))
                                                        navigate('/operation/' + item.id)
                                                    }
                                                },
                                                {
                                                    label: 'Cancella',
                                                    icon: <TrashIcon color="" size={""} />,
                                                    onClick: () => {
                                                        setIsDelete(item.id)
                                                        dispatch(setOpenConfirmModal(true))
                                                    }
                                                }
                                            ]} />
                                        </td>
                                    </tr>
                                )}
                    </tbody>
                </table>
            </div>
            <ConfirmModal
                open={financialAdvisorState.openConfirmModal}
                handleClose={() => dispatch(setOpenConfirmModal(false))}
                title={"Elimina commercialista"}
                description={"Sei sicuro di voler eliminare questo commercialista?"}
                labelDeny={"Annulla"}
                labelConfirm={"Elimina"}
                actionConfirm={() => {
                    isDelete && dispatch(deleteFinancialAdvisor(isDelete))
                    dispatch(setOpenConfirmModal(false))
                }}
            />
        </div>
    )
}