import React, { ReactNode, useState } from "react";
import { useAppSelector } from "../../../lib/redux/hook.ts";
import { ArrowRightIcon } from "../../icons/arrowRightIcon.tsx";

interface Props {
    title: string,
    value: string | ReactNode,
    icon: ReactNode,
    onClick?: () => void
}

export function StatsCard(props: Props) {
    const layoutState = useAppSelector(state => state.layout)
    const [isHover, setIsHover] = useState(false)

    return (
        <div style={{ borderColor: layoutState.theme[200], height: 230 }} className="border rounded-3xl px-6 pt-6 pb-4 flex flex-col flex-grow w-full justify-between shadow-md ">
            <div className=" flex flex-col pt-4 ">
                <div className=" flex flex-row justify-between ">
                    <span className=" text-brandSecondary-850 text-heading-xs font-bold">{props.title}</span>
                    <div style={{ backgroundColor: layoutState.theme[100] }} className=" flex flex-row items-center 
                      justify-center p-2 gap-2 rounded-full size-16">
                        {props.icon}
                    </div>
                </div>
                <div >
                    <span className=" text-brandSecondary-850 text-[72px] leading-[18px]">
                        {props.value}
                    </span>
                </div>
            </div>
            <div className=" flex flex-row justify-between items-center">
                <span onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className=" hover:cursor-pointer text-label-sm" onClick={() => props.onClick && props.onClick()} style={{ color: isHover ? layoutState.theme[500] : layoutState.theme[600] }}>Vai alla sezione</span>
                <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={() => props.onClick && props.onClick()}
                    style={{ backgroundColor: isHover ? layoutState.theme[100] : layoutState.theme[200] }}
                    className=" cursor-pointer flex flex-row items-center justify-center rounded-full size-6">
                    <ArrowRightIcon size={"16px"} color={layoutState.theme[500]} />
                </div>
            </div>
        </div>
    )
}

