
import React, { useEffect, useState } from "react";
import { keycloak } from "../../../lib/keycloak/index.ts";
import { findFileById, setFindDocumentStatus } from "../../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hook.ts";
import { PromiseStatuses, toBase64 } from "../../../lib/utilis/index.ts";
import { colors } from "../../../ui/colors.ts";
import { EmailIcon } from "../../../ui/icons/emailIcon.tsx";
import Avatar from "../../../ui/molecules/avatar/index.tsx";
import { ButtonComponent } from "../../../ui/molecules/button/index.tsx";
import { FileInputComponent } from "../../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../../ui/molecules/input/index.tsx";
import { SelectComponent } from "../../../ui/molecules/select/index.tsx";
import { SpinnerComponent } from "../../../ui/molecules/spinner/index.tsx";
import { ModalComponent } from "../../../ui/organisms/modal/index.tsx";
import { findAllCompany } from "../../company/slice.ts";
import { createCollaborator, editCollaborator, resetCreateCollaboratorsRequest, setCreateCollaboratorsCompanyId, setCreateCollaboratorsEmail, setCreateCollaboratorsName, setCreateCollaboratorsNote, setCreateCollaboratorsPhoneNumber, setCreateCollaboratorsSurname, setCreateFinancialAdvisordId, setEditCollaboratorEmail, setEditCollaboratorName, setEditCollaboratorNote, setEditCollaboratorPhoneNumber, setEditCollaboratorSurname, setFindByIdCollaboratorStatus, setOpenNewCollaboratorModal } from "./slice.ts";
export function NewCollaboratorModal() {
    const collaboratorState = useAppSelector(state => state.collaborator)
    const financialAdvisorState = useAppSelector(state => state.financialAdvisor)
    const companyState = useAppSelector(state => state.company)
    const objectState = useAppSelector(state => state.object)
    const layoutState = useAppSelector(state => state.layout)
    const authState = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState<PromiseStatuses>("idle")
    const [file, setFile] = useState<File | null>(null)
    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    let formData = new FormData();

    useEffect(() => {
        if (collaboratorState.openNewCollaboratorModal) {
            setFile(null)
            setAvatarToBase64('')
        }
    }, [collaboratorState.openNewCollaboratorModal])

    useEffect(() => {
        if (keycloak.hasRealmRole("financial_advisor") || keycloak.hasRealmRole("admin") || keycloak.hasRealmRole("operator")) {
            dispatch(findAllCompany(companyState.companyFilters))
        }
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (collaboratorState.openNewCollaboratorModal && collaboratorState.saveOrEditModal === "edit")
            setLoading("loading")
    }, [collaboratorState.openNewCollaboratorModal, collaboratorState.saveOrEditModal])

    useEffect(() => {
        if (financialAdvisorState.findByIdFinancialAdvisorStatus === "successfully" && collaboratorState.openNewCollaboratorModal) {
            dispatch(setCreateFinancialAdvisordId(financialAdvisorState.findByIdFinancialAdvisorResponse?.id))
            dispatch(findAllCompany({
                financialAdvisorId: financialAdvisorState.findByIdFinancialAdvisorResponse?.id ?? '', sort: "", page: 0, itemsPerPage: 0, companyName: "", order: false, status: ""
            }))
        }
    }, [financialAdvisorState.findByIdFinancialAdvisorStatus, collaboratorState.openNewCollaboratorModal])

    useEffect(() => {
        if (collaboratorState.findByIdCollaboratoreStatus === "successfully" &&
            collaboratorState.saveOrEditModal === 'edit' &&
            companyState.findAllCompanyStatus === "successfully"
        ) {
            dispatch(findFileById(collaboratorState.findByIdCollaboratoreResponse?.avatarObjectId!))
            dispatch(setEditCollaboratorName(collaboratorState.findByIdCollaboratoreResponse?.name))
            dispatch(setEditCollaboratorSurname(collaboratorState.findByIdCollaboratoreResponse?.surname))
            dispatch(setEditCollaboratorEmail(collaboratorState.findByIdCollaboratoreResponse?.email))
            dispatch(setEditCollaboratorPhoneNumber(collaboratorState.findByIdCollaboratoreResponse?.phoneNumber))
            dispatch(setEditCollaboratorNote(collaboratorState.findByIdCollaboratoreResponse?.note))
            setLoading("successfully")
        }
    }, [collaboratorState.findByIdCollaboratoreStatus, collaboratorState.saveOrEditModal, companyState.findAllCompanyStatus])

    useEffect(() => {
        if (objectState.findDocumentStatus === "successfully" && collaboratorState.saveOrEditModal === 'edit') {
            setAvatarToBase64(objectState.document!)
            dispatch(setFindDocumentStatus('idle'))
        }
    }, [objectState.findDocumentStatus])

    let companyName: string[] = []
    if (collaboratorState.findByIdCollaboratoreStatus === "successfully" && collaboratorState.openNewCollaboratorModal && collaboratorState.saveOrEditModal === "edit") {
        companyName = collaboratorState.findByIdCollaboratoreResponse?.companies.map(id => id) ?? []
    }

    return (
        <ModalComponent
            width="medium"
            open={collaboratorState.openNewCollaboratorModal}
            handleClose={() => {
                dispatch(setOpenNewCollaboratorModal(false));
            }}
        >
            <div
                style={{
                    padding: '20px',
                    backgroundColor: colors.white,
                    boxShadow: '0px 12px 24px 0px rgba(22, 42, 100, 0.12)',
                    borderRadius: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%'
                }}
            >
                {
                    collaboratorState.findByIdCollaboratoreStatus === "loading" ||
                        loading === "loading" ||
                        companyState.findAllCompanyStatus === "loading" ||
                        objectState.findDocumentStatus === "loading" ?
                        <div className="flex justify-center items-center">
                            <SpinnerComponent />
                        </div>
                        :
                        <>
                            <div className="flex items-center justify-between">
                                <span className="text-heading-xs font-semibold text-neutral-600">{collaboratorState.saveOrEditModal === 'save' ? "Aggiungi collaboratore" : "Modifica collaboratore"}</span>
                            </div>
                            <div className="flex flex-col items-start" style={{ gap: '16px', width: '100%' }}>
                                <div className="flex gap-[16px] flex-row w-full">
                                    <Avatar
                                        type="single"
                                        shape="circle"
                                        size="md"
                                        imageUrl={avatarToBase64}
                                        altTextInitials={collaboratorState.saveOrEditModal === 'save' ?
                                            (collaboratorState.createCollaboratorRequest.name[0] || 'A') + (collaboratorState.createCollaboratorRequest.surname[0] || 'A')
                                            :
                                            (collaboratorState.editCollaboratorRequest.name[0] || 'A') + (collaboratorState.editCollaboratorRequest.surname[0] || 'A')
                                        }
                                    />
                                    <FileInputComponent
                                        id={"create-collaborator-avatar"}
                                        onChange={(e) => {
                                            if (e.length) {
                                                setFile(e[0])
                                                toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                                            }
                                        }}
                                        color={"gray"}
                                        iconSize="32"
                                        iconBackground={colors.neutral[100]}
                                        infoText={false}
                                        disabled={(!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                                    />
                                </div>
                                <InputComponent
                                    type={"text"}
                                    defaultValue={collaboratorState.saveOrEditModal === 'save' ? collaboratorState.createCollaboratorRequest.name : collaboratorState.editCollaboratorRequest.name}
                                    placeholder={"Nome"}
                                    onChangeText={(value) => {
                                        if (collaboratorState.saveOrEditModal === 'save')
                                            dispatch(setCreateCollaboratorsName(value))
                                        else
                                            dispatch(setEditCollaboratorName(value))
                                    }}
                                    required
                                    disabled={(!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                                />
                                <InputComponent
                                    type={"text"}
                                    defaultValue={collaboratorState.saveOrEditModal === 'save' ? collaboratorState.createCollaboratorRequest.surname : collaboratorState.editCollaboratorRequest.surname}
                                    placeholder={"Cognome"}
                                    onChangeText={(value) => {
                                        if (collaboratorState.saveOrEditModal === 'save')
                                            dispatch(setCreateCollaboratorsSurname(value))
                                        else
                                            dispatch(setEditCollaboratorSurname(value))
                                    }}
                                    required
                                    disabled={(!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                                />
                                <InputComponent
                                    type={"text"}
                                    placeholder="Email"
                                    startIcon={<EmailIcon size={"20"} color={layoutState.theme[300]} />}
                                    defaultValue={collaboratorState.saveOrEditModal === 'save' ? collaboratorState.createCollaboratorRequest.email : collaboratorState.editCollaboratorRequest.email}
                                    onChangeText={(value) => dispatch(setCreateCollaboratorsEmail(value))}
                                    required
                                    disabled={collaboratorState.saveOrEditModal === 'edit' || (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                                    }
                                />
                                <InputComponent
                                    type={"text"}
                                    defaultValue={collaboratorState.saveOrEditModal === 'save' ? collaboratorState.createCollaboratorRequest.phoneNumber : collaboratorState.editCollaboratorRequest.phoneNumber}
                                    placeholder={"Telefono"}
                                    onChangeText={(value) => {
                                        if (collaboratorState.saveOrEditModal === 'save')
                                            dispatch(setCreateCollaboratorsPhoneNumber(value))
                                        else
                                            dispatch(setEditCollaboratorPhoneNumber(value))
                                    }}
                                    required
                                    disabled={(!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                                />
                                {
                                    companyState.selectedSettingTab !== 2 &&
                                    <SelectComponent
                                        key={"new-collaborators-company-list"}
                                        label="Aziende associate"
                                        placeholder={"Seleziona le aziende"}
                                        options={companyState.findAllCompanyResponse?.data.map((value) => ({ label: value.companyName, value: value.id }))!}
                                        isMulti
                                        onChange={(value) => dispatch(setCreateCollaboratorsCompanyId(value))}
                                        value={collaboratorState.saveOrEditModal === "save" ? collaboratorState.createCollaboratorRequest?.companyIds.toString() : companyName}
                                        disabled={collaboratorState.saveOrEditModal === "edit" || companyState.findAllCompanyResponse?.total === 0 || (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))
                                        }
                                    />
                                }
                                <InputComponent
                                    type={"text"}
                                    label="Descrizione"
                                    placeholder="Un po' di informazioni su di te"
                                    supportingText={(275 - financialAdvisorState.createFinancialAdvisorRequest.note.length) + " caratteri rimasti"}
                                    multiple
                                    defaultValue={collaboratorState.saveOrEditModal === 'save' ? collaboratorState.createCollaboratorRequest.note : collaboratorState.editCollaboratorRequest.note}
                                    onChangeText={(value) => {
                                        if (collaboratorState.saveOrEditModal === 'save')
                                            dispatch(setCreateCollaboratorsNote(value))
                                        else
                                            dispatch(setEditCollaboratorNote(value))
                                    }}
                                    disabled={(!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                                />
                            </div>
                            <div className={"flex gap-3 items-center"}>
                                <ButtonComponent
                                    label={"Annulla"}
                                    onClick={() => {
                                        dispatch(setOpenNewCollaboratorModal(false))
                                        if (collaboratorState.saveOrEditModal === "save") {
                                            dispatch(resetCreateCollaboratorsRequest())
                                        } else {
                                            dispatch(setEditCollaboratorName(""))
                                            dispatch(setEditCollaboratorSurname(""))
                                            dispatch(setEditCollaboratorPhoneNumber(""))
                                            dispatch(setFindByIdCollaboratorStatus('idle'))
                                            setLoading("idle")
                                        }
                                    }}
                                    color={"gray"}
                                    variant={"outline"}
                                    size={"sm"}
                                    iconPosition="off"
                                    fullWidth
                                    disabled={(!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))}
                                />
                                <ButtonComponent
                                    label={"Avvia procedura"}
                                    onClick={() => {
                                        if (collaboratorState.saveOrEditModal === "save") {
                                            if (file !== null) {
                                                formData.append('avatar', file)
                                            }
                                            formData.append('collaboratorCreationDTO', JSON.stringify(collaboratorState.createCollaboratorRequest))
                                            dispatch(createCollaborator(formData))
                                            dispatch(setOpenNewCollaboratorModal(false))
                                        } else {
                                            if (file !== null) {
                                                formData.append('avatar', file)
                                            }
                                            formData.append('userDto', JSON.stringify(collaboratorState.editCollaboratorRequest))
                                            dispatch(editCollaborator({ id: collaboratorState.findByIdCollaboratoreResponse?.id !== undefined ? collaboratorState.findByIdCollaboratoreResponse?.id : "", request: formData }))
                                            dispatch(setOpenNewCollaboratorModal(false))
                                        }

                                    }}
                                    color={"blue"}
                                    variant={"solid"}
                                    size={"sm"}
                                    iconPosition="off"
                                    fullWidth
                                    disabled={
                                        collaboratorState.saveOrEditModal === "save" && (
                                            collaboratorState.createCollaboratorRequest.name === "" ||
                                            collaboratorState.createCollaboratorRequest.surname === "" ||
                                            collaboratorState.createCollaboratorRequest.email === ""
                                        ) ||
                                        collaboratorState.saveOrEditModal === "edit" && (
                                            collaboratorState.editCollaboratorRequest.name === "" ||
                                            collaboratorState.editCollaboratorRequest.surname === ""
                                        ) || (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) || (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator"))

                                    }
                                />
                            </div>
                        </>
                }
            </div>
        </ModalComponent>
    )
}