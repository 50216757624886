import React from "react"

export type PromiseStatuses = 'idle' | 'loading' | 'successfully' | 'failed'
export type OnButtonClick = React.MouseEventHandler<HTMLButtonElement> | undefined
export type OnMenuItemClick = React.MouseEventHandler<HTMLLIElement> | undefined
export type OnChange = React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnFocus = React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined
export type OnKeyDown = React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined

export const generatePagination = (currentPage: number, totalPages: number) => {
    if (totalPages <= 7) {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
    if (currentPage <= 3) {
        return [1, 2, 3, '...', totalPages - 1, totalPages];
    }
    if (currentPage >= totalPages - 2) {
        return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages];
    }
    return [
        1,
        '...',
        currentPage - 1,
        currentPage,
        currentPage + 1,
        '...',
        totalPages,
    ];
};

export function capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getMimeTypeFromBase64 = (file: string): string | undefined => {
    const removeData = file.split('data:')[1]
    return removeData.split(';')[0]
};

export const monthMap = new Map<number, string>([
    [1, 'Gennaio'],
    [2, 'Febbraio'],
    [3, 'Marzo'],
    [4, 'Aprile'],
    [5, 'Maggio'],
    [6, 'Giugno'],
    [7, 'Luglio'],
    [8, 'Agosto'],
    [9, 'Settembre'],
    [10, 'Ottobre'],
    [11, 'Novembre'],
    [12, 'Dicembre']
])

export async function toBase64(file: File): Promise<string> {
    const bytes = new Uint8Array( file && await file.arrayBuffer());

    let binary = '';

    for (let i = 0; i < bytes.byteLength; i++) {

        binary += String.fromCharCode(bytes[i]);

    }
    return btoa(binary);
}

const adjustBrightness = (hex: string, percent: number): string => {
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);

    r = Math.min(255, Math.max(0, r + Math.round((255 - r) * percent / 100)));
    g = Math.min(255, Math.max(0, g + Math.round((255 - g) * percent / 100)));
    b = Math.min(255, Math.max(0, b + Math.round((255 - b) * percent / 100)));

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
};


export const generateTheme = (primaryColor: string) => {
    const theme: Record<number, string> = {};

    const steps = [
        { level: 900, brightnessAdjustment: -50 },
        { level: 850, brightnessAdjustment: -40 },
        { level: 800, brightnessAdjustment: -30 },
        { level: 700, brightnessAdjustment: -20 },
        { level: 600, brightnessAdjustment: -10 },
        { level: 500, brightnessAdjustment: 0 },  
        { level: 400, brightnessAdjustment: 30 },
        { level: 300, brightnessAdjustment: 50 },
        { level: 200, brightnessAdjustment: 70 },
        { level: 100, brightnessAdjustment: 90 },
        { level: 50, brightnessAdjustment: 98 },
    ];

    steps.forEach(({ level, brightnessAdjustment }) => {
        theme[level] = adjustBrightness(primaryColor, brightnessAdjustment);
    });

    return theme;
};

