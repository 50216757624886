import { createSlice } from "@reduxjs/toolkit"

export const urlMap = new Map<string, string>([
    ['http://localhost:3000', 'ws://localhost:8088'],
    ['https://staging-app.fiscalbright.it', 'wss://staging-app.fiscalbright.it'],
])

interface SecuredState {
    userRoles: string[]
    keycloakInitStatus: 'idle' | 'loading' | 'successfully' | 'failed'
    socket?: WebSocket
}

const initialState: SecuredState = {
    userRoles: [],
    keycloakInitStatus: 'idle',
}

const securedSlice = createSlice({
    name: 'secured/slice',
    initialState,
    reducers: {
        setRoles: (state, action) => {
            state.userRoles = action.payload
        },
        setKeycloakInitStatus: (state, action) => {
            state.keycloakInitStatus = action.payload
        },
        setSocket: (state, action) => {
            state.socket = action.payload
        }
    },
})

export const {
    setRoles,
    setKeycloakInitStatus,
    setSocket
} = securedSlice.actions

export default securedSlice.reducer