import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import reportWebVitals from './reportWebVitals.ts';
import React from 'react';
import { keycloak} from './app/lib/keycloak/index.ts';
import { store } from './app/lib/redux/store.ts'
import { Provider } from 'react-redux';

keycloak.init({
  onLoad: window.location.pathname === '/reg'? 'check-sso' : 'login-required',
  redirectUri: window.location.pathname === '/reg' ? window.location.origin: window.location.href  ,
  pkceMethod: "S256"
})
  .then()
  .catch((error) => { })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

keycloak.onTokenExpired = () => {
  keycloak.updateToken(300)
    .catch(() => {
      keycloak.logout()
    });
}

keycloak.onAuthRefreshError = () => {
  keycloak.logout()
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();