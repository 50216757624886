import React, { ReactNode, useState } from "react"
import { useAppSelector } from "../../../lib/redux/hook.ts"
import { ArrowRightIcon } from "../../icons/arrowRightIcon.tsx"

interface Props {
    title: string,
    content: ReactNode,
    label?: string,
    rightLabel?: ReactNode,
    onClick?: () => void
}
export function StatsCardAlt(props: Props) {
    const layoutState = useAppSelector(state => state.layout)
    const [isHover, setIsHover] = useState(false)

    return (
        <div style={{ borderColor: layoutState.theme[200], height: 230 }} className="flex-grow flex flex-col gap-4 justify-between 
          border rounded-3xl shadow-md px-6 pt-6 pb-4 w-full">
            <div className="flex flex-row justify-between">
                <span className=" text-brandPrimary-850 font-bold leading-[18px] text-lg">{props.title}</span>
                {props.rightLabel}
            </div>
            <div>
                {props.content}
            </div>
            {props.label &&
                <div className=" flex flex-row justify-between items-end cursor-pointer">
                    <span onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={() => props.onClick && props.onClick()} className="text-label-sm font-medium" style={{ color: isHover ? layoutState.theme[500] : layoutState.theme[600] }}>
                        {props.label}
                    </span>
                    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onClick={() => props.onClick && props.onClick()} style={{ backgroundColor: isHover ? layoutState.theme[100] : layoutState.theme[200] }} className=" cursor-pointer flex flex-row items-center 
                                          justify-center rounded-full size-6">
                        <ArrowRightIcon size={"16px"} color={layoutState.theme[500]} />
                    </div>
                </div>
            }
        </div>
    )
}