
import React, { ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { keycloak } from '../../../lib/keycloak/index.ts'
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hook.ts'
import { LogoExtended } from '../../../ui/icons/logoExtended.tsx'
import { ButtonComponent } from '../../../ui/molecules/button/index.tsx'
import { InputComponent } from '../../../ui/molecules/input/index.tsx'
import { FinancialAdvisorRegisterForm } from './form.tsx'
import { setSpontaneousFinancialAdvisorsEmail } from './slice.ts'
import { SubscriptionChoice } from './subscriptionChoice.tsx'
import { SuccesfulSignup } from './succesfulSignup.tsx'
import { ProgressStep } from '../../../ui/molecules/progressStep/index.tsx'

export function Register() {
  const [canGoOn, setCanGoOn] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const spontaneousState = useAppSelector(state => state.spontaneous)
  const stepMap = new Map<number, ReactElement>([
    [1, <FinancialAdvisorRegisterForm />],
    [2, <SubscriptionChoice />],
    [3, <SuccesfulSignup />],
  ]
  )

  return (
    <div className='py-20 bg-brandPrimary-50 min-h-screen'>
      <div className='flex flex-col items-center w-full px-10'>
        <div className='flex flex-col gap-8 w-full  items-center'>
          <LogoExtended />
          {
            !canGoOn &&
            <>
              <h1 className='text-center font-bold text-neutral-850 text-heading-xl'>Crea il tuo account</h1>
              <div className='bg-white border border-brandPrimary-200 shadow-sm flex flex-col items-center 
                 rounded-xl p-8 gap-6'>
                <div className='flex flex-col gap-6 w-full'>
                  <InputComponent
                    onChangeText={(t) => dispatch(setSpontaneousFinancialAdvisorsEmail(t))}
                    required
                    label='Indirizzo email'
                    placeholder='nome@dominio.com'
                    type={'email'}
                  />
                  <ButtonComponent
                    disabled={!/^[A-z0-9\.\+_-]+@[A-z0-9\._-]+\.[A-z]{2,6}$/.test(spontaneousState.spontaneousFinancialAdvisorRequest.email)}
                    onClick={() => { setCanGoOn(true) }}
                    label='Avanti'
                    fullWidth
                    size={'md'}
                    iconPosition={'off'}
                    variant={'solid'}
                    color={'blue'}
                  />
                </div>
                <div className='flex flex-col border-t border-brandPrimary-200 gap-8 items-center pt-6'>
                  <div className='flex flex-row items-center gap-1'>
                    <span className=' text-text-md font-medium'>Hai già un account?</span>
                    <span onClick={()=>{
                        navigate('/')
                        keycloak.login()
                      }} className=' text-text-md font-semibold text-brandPrimary-500 cursor-pointer'>Accedi qui</span>
                  </div>
                  <div className='text-center text-text-sm text-brandSecondary-500 w-[60%]'>
                    This site is protected by reCAPTCHA and the Google
                    <span className='underline'> Privacy Policy</span> and <span className='underline'>Terms of Service</span> apply.
                  </div>
                </div>
              </div>
            </>
          }
          {
            canGoOn &&
            <div className='flex flex-col items-center gap-8 w-full'>
              <ProgressStep isVisible={spontaneousState.signupStep < 3} color='primary' size='md' stepMap={stepMap}
                stepNumber={spontaneousState.signupStep} symbol='number' />
            </div>
          }
        </div>
      </div>
    </div>
  )
}
