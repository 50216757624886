import React, { ReactElement, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { keycloak } from "../../lib/keycloak/index.ts"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts"
import { colors } from "../../ui/colors.ts"
import { ArrowLeftIcon } from "../../ui/icons/arrowLeftIcon.tsx"
import { CompanyIcon } from "../../ui/icons/companyIcon.tsx"
import { SettingIcon } from "../../ui/icons/settingIcon.tsx"
import { LayoutComponent } from "../../ui/layout/index.tsx"
import { ButtonComponent } from "../../ui/molecules/button/index.tsx"
import { ErrorPopup } from "../../ui/organisms/errorPopup/index.tsx"
import { HorizontalTab } from "../../ui/organisms/horizontalTab/index.tsx"
import { MenuItems } from "../../ui/organisms/sidebar/dto.ts"
import { SuccessPopup } from "../../ui/organisms/successPopup/index.tsx"
import { Tab } from "../../ui/organisms/tab/index.tsx"
import { BankAccount } from "./bankAccount/index.tsx"
import { findAcubeConnectRequest } from "./bankAccount/slice.ts"
import { CompanyStatus, tabSettingCompanyMap, tabSettingMap } from "./dto.ts"
import { setEventFilterCompanyId } from "./events/slice.ts"
import { Referrers } from "./referrers/index.tsx"
import { editReferrer, setCreateReferrersClientId, setEditReferrersStatus, setReferrersFiltersCompanyId } from "./referrers/slice.ts"
import { RegistryCompany } from "./registryCompany.tsx"
import { Roles } from "./roles.tsx"
import { addCollaboratorsToCompany, editCompany, findByIdCompany, setEditCompanyStatus, setFindByIdCompanyStatus, setSelectedSettingTab } from "./slice.ts"

export function SettingsCompany() {
    const navigation = useNavigate()
    const dispatch = useAppDispatch()

    const companyState = useAppSelector(state => state.company)
    const authState = useAppSelector(state => state.auth)
    const referrersState = useAppSelector(state => state.referrers)
    const bankState = useAppSelector(state => state.bank)

    let formData = new FormData();
    let id = keycloak.hasRealmRole("company") ? authState.findMeCompanyResponse?.id : keycloak.hasRealmRole("administrative_company") ? authState.findMeReferrersResponse?.id : companyState.findByIdCompanyResponse?.id

    useEffect(() => {
        if (!keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company"))
            dispatch(findByIdCompany(companyState.companyId))
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (companyState.findByIdCompanyStatus === "successfully") {
            dispatch(setReferrersFiltersCompanyId(companyState.findByIdCompanyResponse?.id))
            dispatch(setEventFilterCompanyId(companyState.findByIdCompanyResponse?.id))
            dispatch(setCreateReferrersClientId(companyState.findByIdCompanyResponse?.id))
        }
    }, [companyState.findByIdCompanyStatus])

    useEffect(() => {
        if (authState.findMeCompanyStatus === "successfully") {
            dispatch(setCreateReferrersClientId(authState.findMeCompanyResponse?.id))
        }
    }, [authState.findMeCompanyStatus])

    const viewComponentMap = new Map<number, ReactElement>([
        [0, <RegistryCompany />],
        [1, <Referrers />],
        [2, <Roles />],
        [3, <BankAccount />]
    ])

    return (
        <LayoutComponent
            menuItem={!keycloak.hasRealmRole('company') && !keycloak.hasRealmRole("administrative_company") ? MenuItems.COMPANY : window.location.pathname === '/document-company' ? MenuItems.DOCUMENT : MenuItems.SETTING}
            headingLabel={keycloak.hasRealmRole('company') ? authState.findMeCompanyResponse?.companyName! : keycloak.hasRealmRole("administrative_company") ? (authState.findMeReferrersResponse?.name + ' ' + authState.findMeReferrersResponse?.surname) : companyState.findByIdCompanyResponse?.companyName!}
            headingButtons={[
                <div className={"flex gap-2"}>
                    {
                        !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company") &&
                        <ButtonComponent
                            label={"Torna all'azienda"}
                            onClick={() => {
                                navigation('/operation-company/' + companyState.findByIdCompanyResponse?.id)
                            }}
                            color={"gray"}
                            variant={"outline"}
                            size={"md"}
                            iconPosition={"left"}
                            icon={<ArrowLeftIcon size={"24"} color={colors.neutral[500]} />}
                        />
                    }
                    <ButtonComponent
                        label={companyState.selectedSettingTab === 3 ? "Consenti accesso" : "Aggiorna"}
                        onClick={() => {
                            if (companyState.selectedSettingTab === 0 && !keycloak.hasRealmRole("administrative_company")) {
                                if (companyState.editCompanyRequest.avatar !== null)
                                    formData.append('avatar', companyState.editCompanyRequest.avatar)
                                formData.append('user', JSON.stringify(companyState.editCompanyRequest))
                                dispatch(editCompany({ id: id !== undefined ? id : '', request: formData }))
                                if (!keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company")) {
                                    dispatch(setFindByIdCompanyStatus('idle'))
                                }
                            } else if (companyState.selectedSettingTab === 0 && keycloak.hasRealmRole("administrative_company")) {
                                if (referrersState.editReferrersRequest.avatar !== null)
                                    formData.append('avatar', referrersState.editReferrersRequest.avatar)
                                formData.append('user', JSON.stringify(referrersState.editReferrersRequest))
                                dispatch(editReferrer({ id: id !== undefined ? id : "", request: formData }))
                            } else if (companyState.selectedSettingTab === 2) {
                                dispatch(addCollaboratorsToCompany({ companyId: companyState.findByIdCompanyResponse?.id ?? '', collaboratorIds: companyState.addCollaboratorsToList }))
                            } else {
                                dispatch(findAcubeConnectRequest(keycloak.hasRealmRole("company") && authState.findMeCompanyResponse !== undefined ? authState.findMeCompanyResponse?.id : authState.findMeReferrersResponse?.company!))
                            }
                        }}
                        color={"blue"}
                        variant={"solid"}
                        size={"md"}
                        iconPosition={"off"}
                        disabled={
                            keycloak.hasRealmRole("company") &&
                            (companyState.editCompanyRequest.name === "" ||
                                companyState.editCompanyRequest.surname === "" ||
                                companyState.editCompanyRequest.companyName === "") ||
                            keycloak.hasRealmRole("administrative_company") &&
                            (referrersState.editReferrersRequest.name === "" ||
                                referrersState.editReferrersRequest.surname === "") ||
                            companyState.findByIdCompanyResponse?.status === CompanyStatus.SUSPENDED ||
                            (!authState.findMeFinancialAdvisorResponse?.isActive && keycloak.hasRealmRole("financial_advisor")) ||
                            (!authState.findMeCollaboratorResponse?.isActive && keycloak.hasRealmRole("financial_advisor_collaborator")) ||
                            (!authState.findMeCompanyResponse?.isActive && keycloak.hasRealmRole("company")) ||
                            (!authState.findMeReferrersResponse?.isActive && keycloak.hasRealmRole("administrative_company"))
                        }
                    />
                </div>
            ]}
            breadcrumbItems={!keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company") ? tabSettingMap[companyState.selectedSettingTab] : tabSettingCompanyMap[companyState.selectedSettingTab]}
            breadcrumbIcon={!keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company") ? <CompanyIcon size={"20"} color={colors.brandSecondary[500]} /> : <SettingIcon color={colors.brandSecondary[500]} size={"20"} />}
        >
            <ErrorPopup
                active={companyState.editCompanyStatus === 'failed'}
                close={() => dispatch(setEditCompanyStatus('idle'))}
                message="Si è verificato un errore durante la modifica del profilo"
            />
            <SuccessPopup
                active={companyState.editCompanyStatus === 'successfully'}
                close={() => dispatch(setEditCompanyStatus('idle'))}
                message="Profilo modificato"
            />
            <SuccessPopup
                active={referrersState.editReferrersStatus === "successfully"}
                close={() => dispatch(setEditReferrersStatus("idle"))}
                message="Profilo modificato"
            />
            <ErrorPopup
                active={referrersState.editReferrersStatus === "failed"}
                close={() => dispatch(setEditReferrersStatus("idle"))}
                message="Errore durante la modifica del profilo."
            />
            <HorizontalTab>
                <Tab
                    label={"Anagrafica"}
                    selectedPrimary={companyState.selectedSettingTab === 0}
                    onClick={() => dispatch(setSelectedSettingTab(0))}
                    type="primary"
                />
                <Tab
                    label={"Referenti Amministrativi"}
                    selectedPrimary={companyState.selectedSettingTab === 1}
                    onClick={() => dispatch(setSelectedSettingTab(1))}
                    type="primary"
                />
                {
                    !keycloak.hasRealmRole("company") && !keycloak.hasRealmRole("administrative_company") && !keycloak.hasRealmRole("financial_advisor_collaborator") &&
                    <Tab
                        label={"Ruoli"}
                        selectedPrimary={companyState.selectedSettingTab === 2}
                        onClick={() => dispatch(setSelectedSettingTab(2))}
                        type="primary"
                    />
                }
                {
                    (keycloak.hasRealmRole("company") || keycloak.hasRealmRole("administrative_company")) &&
                    <Tab
                        label={"Conto corrente"}
                        selectedPrimary={companyState.selectedSettingTab === 3}
                        onClick={() => dispatch(setSelectedSettingTab(3))}
                        type="primary"
                    />
                }
            </HorizontalTab>
                {viewComponentMap.get(companyState.selectedSettingTab)}
        </LayoutComponent >
    )
}