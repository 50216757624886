import React, { useEffect, useState } from "react";
import { keycloak } from "../../lib/keycloak/index.ts";
import { findFileById, setFindDocumentStatus } from "../../lib/object/slice.ts";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hook.ts";
import { toBase64 } from "../../lib/utilis/index.ts";
import { colors } from "../../ui/colors.ts";
import { EmailIcon } from "../../ui/icons/emailIcon.tsx";
import Avatar from "../../ui/molecules/avatar/index.tsx";
import { FileInputComponent } from "../../ui/molecules/fileInput/index.tsx";
import { InputComponent } from "../../ui/molecules/input/index.tsx";
import { SpinnerComponent } from "../../ui/molecules/spinner/index.tsx";
import { FormRow } from "../../ui/organisms/formRow/formRow.tsx";
import { findMeOperator, setFindMeOperatorStatus } from "../auth/slice.ts";
import { findByIdOperator, setEditOperatorAvatar, setEditOperatorRequestName, setEditOperatorRequestNote, setEditOperatorRequestSurname } from "./slice.ts";
export function EditOperator() {
    const dispatch = useAppDispatch()

    const operatorState = useAppSelector(state => state.operator)
    const authState = useAppSelector(state => state.auth)
    const objectState = useAppSelector(state => state.object)

    const [avatarToBase64, setAvatarToBase64] = useState<string>('')

    useEffect(() => {
        if (!keycloak.hasRealmRole("operator"))
            dispatch(findByIdOperator(operatorState.operatorId))
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (operatorState.findByIdOperatorStatus === "successfully") {
            dispatch(findFileById(operatorState.findByIdOperatorResponse?.avatarObjectId!))
            dispatch(setEditOperatorRequestName(operatorState.findByIdOperatorResponse?.name))
            dispatch(setEditOperatorRequestSurname(operatorState.findByIdOperatorResponse?.surname))
        }
    }, [operatorState.findByIdOperatorStatus])

    useEffect(() => {
        if (keycloak.hasRealmRole("operator")) {
            dispatch(findFileById(authState.findMeOperatorResponse?.avatarObjectId!))
            dispatch(setEditOperatorRequestName(authState.findMeOperatorResponse?.name))
            dispatch(setEditOperatorRequestSurname(authState.findMeOperatorResponse?.surname))
        }
    }, [keycloak.hasRealmRole])

    useEffect(() => {
        if (objectState.findDocumentStatus === "successfully") {
            setAvatarToBase64(objectState.document!)
            dispatch(setFindDocumentStatus('idle'))
        }
    }, [objectState.findDocumentStatus])

    useEffect(() => {
        if (operatorState.editOperatorStatus === "successfully" && keycloak.hasRealmRole("operator")) {
            dispatch(findMeOperator())
        }
    }, [operatorState.editOperatorStatus, keycloak.hasRealmRole])

    useEffect(() => {
        if (authState.findMeOperatorStatus === "successfully") {
            dispatch(setFindMeOperatorStatus("idle"))
        }
    }, [authState.findMeOperatorStatus])

    if (operatorState.findByIdOperatorStatus === "loading" ||
        authState.findMeOperatorStatus === "loading" ||
        operatorState.editOperatorStatus === "loading" ||
        objectState.findDocumentStatus === "loading") {
        return (
            <div className={"flex justify-center items-center"}>
                <SpinnerComponent />
            </div>
        )
    }

    return (
        <div className={"flex flex-col gap-5"}>
            <FormRow
                label="Nome"
                children={[
                    <>
                        <InputComponent
                            type={"text"}
                            placeholder="Nome"
                            value={operatorState.editOperatorRequest.name}
                            onChangeText={(value) => {
                                dispatch(setEditOperatorRequestName(value))
                            }}
                        />
                        <InputComponent
                            type={"text"}
                            placeholder="Cognome"
                            value={operatorState.editOperatorRequest.surname}
                            onChangeText={(value) => {
                                dispatch(setEditOperatorRequestSurname(value))
                            }}
                        />
                    </>
                ]}
            />
            <FormRow
                label="Email"
                children={[
                    <InputComponent
                        type={"text"}
                        placeholder="Email"
                        startIcon={<EmailIcon size={"20"} color={colors.neutral[400]} />}
                        value={operatorState.findByIdOperatorResponse?.email ?? authState.findMeOperatorResponse?.email}
                        disabled
                    />
                ]}
            />
            <FormRow
                label="Avatar"
                subLabel="Verrà visualizzata sul vostro profilo"
                children={[
                    <div key={"profile-edit-avatar-upload"} className={"flex items-center justify-start gap-4 w-full"}>
                        <Avatar
                            type="single"
                            size="2xl"
                            shape="circle"
                            imageUrl={avatarToBase64}
                            altTextInitials={(operatorState.editOperatorRequest.name[0] || '') + (operatorState.editOperatorRequest.surname[0] || '')}
                        />
                        <FileInputComponent
                            id={"profile-edit-upload"}
                            key={"profile-edit-upload"}
                            infoText
                            color="gray"
                            iconSize="32"
                            iconBackground={colors.neutral[100]}
                            onChange={(e) => {
                                if (e.length) {
                                    dispatch(setEditOperatorAvatar(e[0]))
                                    toBase64(e[0]).then(data => setAvatarToBase64('data:' + e[0].type + ';base64,' + data))
                                }
                            }}
                        />
                    </div>
                ]}
            />
            <FormRow
                label="Note"
                subLabel="Inserisci le note dell'utente"
                children={[
                    <InputComponent
                        type={"text"}
                        placeholder="Un po' di informazioni su di te"
                        supportingText={275 - operatorState.editOperatorRequest.note.length + " caratteri rimasti"}
                        multiple
                        onChangeText={(value) => dispatch(setEditOperatorRequestNote(value))}
                        defaultValue={operatorState.findByIdOperatorResponse?.note ?? authState.findMeOperatorResponse?.note}
                    />
                ]}
            />
        </div>
    )
}